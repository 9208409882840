<template>
  <div class="container">
    <h2>Admin - Search Management</h2>

    <!-- Tabs -->
    <ul class="nav nav-tabs" id="adminTabs" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link active" id="dashboard-tab" data-bs-toggle="tab" href="#dashboard" role="tab"
          aria-controls="dashboard" aria-selected="false">Dashboard</a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="manage-items-tab" data-bs-toggle="tab" href="#manage-items" role="tab"
          aria-controls="manage-items" aria-selected="true">Manage Items</a>
      </li>
    </ul>

    <!-- Tab Content -->
    <div class="tab-content" id="adminTabsContent">

      <!-- Dashboard Tab -->
      <div class="tab-pane fade show active" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
        <div class="dashboard mt-4 row">
          <div class="charts row">
            <!-- Location wise User Trend -->
            <div class="col-md-4 col-xs-12">
              <h4>Location-wise User Trend</h4>
              <canvas id="locationUserChart"></canvas>
            </div>

            <!-- Location wise Search Trend -->
            <div class="col-md-4 col-xs-12">
              <h4>Location-wise Search Trend</h4>
              <canvas id="locationSearchChart"></canvas>
            </div>

            <!-- Category wise Search Trend -->
            <div class="col-md-4 col-xs-12">
              <h4>Category-wise Search Trend</h4>
              <canvas id="categorySearchChart"></canvas>
            </div>
          </div>
        </div>
      </div>

      <!-- Manage Items Tab -->
      <div class="tab-pane fade" id="manage-items" role="tabpanel" aria-labelledby="manage-items-tab">
        <div class="manage-items mt-4">
          <!-- Filters -->
          <div class="row filters justify-content-center">
            <div class="col-auto col-xs-6 filter mt-2">
              <label class="me-1">Category:</label>
              <select v-model="selectedCategory" class="form-select">
                <option v-for="category in categories" :key="category" :value="category">{{ category }}</option>
              </select>
            </div>
            <div class="col-auto col-xs-6 filter mt-2">
              <label class="me-1">Location:</label>
              <select v-model="selectedLocation" class="form-select">
                <option v-for="location in locations" :key="location" :value="location">{{ location }}</option>
              </select>
            </div>
          </div>

          <!-- Item List Table -->
          <div class="table-responsive" style="max-height: 400px; overflow-y: auto;">
            <table class="table table-striped table-bordered">
              <thead class="sticky-top" style="border: 2px solid #dee2e6;">
                <tr>
                  <th>Provider Name</th>
                  <th>Location</th>
                  <th>Speciality</th>
                  <th>TAT</th>
                  <th>Category</th>
                  <th>Active</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in filteredItems" :key="index">
                  <td>{{ item.name }}</td>
                  <td>{{ item.location }}</td>
                  <td>{{ item.speciality }}</td>
                  <td>{{ item.tat }}</td>
                  <td>{{ item.category }}</td>
                  <td>{{ item.active ? 'Yes' : 'No' }}</td>
                  <td>
                    <div class="dropdown">
                      <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" id="actionDropdown"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        Select Action
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="actionDropdown">
                        <li><a class="dropdown-item" href="#" @click.prevent="toggleActivation(index)">{{ item.active ?
                          'Deactivate' : 'Activate' }}</a></li>
                        <li><a class="dropdown-item" href="#" @click.prevent="viewDetails(item)">View Details</a></li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { ReusableModal } from '../../components/modals/ReusableModal.vue';
import { Chart, LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, BarController, BarElement, PieController, ArcElement } from 'chart.js';
import { Providers } from '../../data/providers';

export default {
  components: {
    ReusableModal
  },
  setup() {
    const categories = ref(['All', 'Health', 'Education', 'IT', 'Finance']);
    const locations = ref(['All', 'New York', 'Los Angeles', 'Chicago', 'San Francisco']);
    const selectedCategory = ref('All');
    const selectedLocation = ref('All');
    const items = ref(Providers);

    const filteredItems = computed(() => {
      return items.value.filter(item =>
        (selectedCategory.value === 'All' || item.category === selectedCategory.value) &&
        (selectedLocation.value === 'All' || item.location === selectedLocation.value)
      );
    });

    const toggleActivation = (index) => {
      items.value[index].active = !items.value[index].active;
    };

    const viewDetails = (item) => {
      alert(`Viewing details of ${item.providerName}`);
    };

    const renderCharts = () => {
      // Register the controllers, elements, and scales
      Chart.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, BarController, BarElement, PieController, ArcElement);

      // Location wise User Trend (Line Chart)
      new Chart(document.getElementById('locationUserChart'), {
        type: 'line',
        data: {
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
          datasets: [{
            label: 'Users',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1
          }]
        }
      });

      // Location wise Search Trend (Bar Chart)
      new Chart(document.getElementById('locationSearchChart'), {
        type: 'bar',
        data: {
          labels: ['New York', 'Los Angeles', 'Chicago', 'San Francisco'],
          datasets: [{
            label: 'Searches',
            data: [150, 100, 200, 250],
            backgroundColor: 'rgba(153, 102, 255, 0.2)',
            borderColor: 'rgba(153, 102, 255, 1)',
            borderWidth: 1
          }]
        }
      });

      // Category wise Search Trend (Pie Chart)
      new Chart(document.getElementById('categorySearchChart'), {
        type: 'pie',
        data: {
          labels: ['Health', 'Education', 'IT', 'Finance'],
          datasets: [{
            label: 'Searches by Category',
            data: [100, 200, 150, 50],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)'
            ],
            borderWidth: 1
          }]
        }
      });
    };

    onMounted(() => {
      renderCharts();
    });

    return {
      categories,
      locations,
      selectedCategory,
      selectedLocation,
      items,
      filteredItems,
      toggleActivation,
      viewDetails
    };
  }
};
</script>
<style scoped>
.container {
  padding: 20px;
}

.manage-items,
.dashboard {
  margin-bottom: 40px;
}

.filters {
  display: flex;
  margin-bottom: 20px;
}

.table {
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 10px;
  border: 1px solid #ddd;
}
</style>