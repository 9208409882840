<template>
    <div class="modal fade" tabindex="-1" :id="modalId" aria-labelledby="modalLabel" aria-hidden="true">
      <div class="modal-dialog" :class="modalSize">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ title }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <slot name="body"></slot>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="onCancel">Cancel</button>
            <button type="button" class="btn btn-primary" @click="onConfirm">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
      modalId: {
        type: String,
        required: true,
      },
      modalSize: {
        type: String,
        default: 'modal-lg',
      },
      onConfirm: {
        type: Function,
        default: () => {},
      },
      onCancel: {
        type: Function,
        default: () => {},
      },
    },
  };
  </script>  