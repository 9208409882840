<template>
    <div class="container">
    <!-- Tabs -->
    <ul class="nav nav-tabs" id="helpDeskTabs" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link active" id="submit-request-tab" data-bs-toggle="tab" href="#submit-request" role="tab" aria-controls="submit-request" aria-selected="true">Submit a Help Request</a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="review-requests-tab" data-bs-toggle="tab" href="#review-requests" role="tab" aria-controls="review-requests" aria-selected="false">Review Help Requests</a>
      </li>
    </ul>

    <!-- Tab Content -->
    <div class="tab-content" id="helpDeskTabsContent">
      <!-- Submit Request Tab -->
      <div class="tab-pane fade show active" id="submit-request" role="tabpanel" aria-labelledby="submit-request-tab">
        <div class="card mb-4">
          <div class="card-body">
            <h5 class="card-title">Submit a Help Request</h5>
            <form @submit.prevent="submitRequest">
              <div class="mb-3">
                <label for="subject" class="form-label">Subject</label>
                <input v-model="newRequest.subject" type="text" class="form-control" id="subject" required>
              </div>
              <div class="mb-3">
                <label for="description" class="form-label">Description</label>
                <textarea v-model="newRequest.description" class="form-control" id="description" rows="3" required></textarea>
              </div>
              <div class="mb-3">
                <label for="attachment" class="form-label">Attachment (Optional)</label>
                <input @change="handleFileUpload" type="file" class="form-control" id="attachment">
              </div>
              <button type="submit" class="btn btn-primary me-2">Submit</button>
              <button type="button" @click="resetForm" class="btn btn-secondary">Reset</button>
            </form>
          </div>
        </div>
      </div>

      <!-- Review Requests Tab -->
      <div class="tab-pane fade" id="review-requests" role="tabpanel" aria-labelledby="review-requests-tab">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Review Help Requests</h5>
            <div class="mb-3">
              <label for="statusFilter" class="form-label">Filter by Status</label>
              <select v-model="statusFilter" @change="filterRequests" class="form-select" id="statusFilter">
                <option value="all">All</option>
                <option value="closed">Closed</option>
                <option value="pending">Pending</option>
                <option value="expired">Expired</option>
              </select>
            </div>
            <div class="table-responsive">
              <table class="table table-striped table-bordered">
                <thead class="sticky-top" style="border: 2px solid #dee2e6;">
                  <tr>
                    <th>Request UID</th>
                    <th>Submission Date</th>
                    <th>Status</th>
                    <th>Expiration</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="request in filteredRequests" :key="request.id">
                    <td>{{ request.uid }}</td>
                    <td>{{ formatDate(request.submissionDate) }}</td>
                    <td>{{ request.status }}</td>
                    <td>{{ formatDate(calculateExpiration(request.submissionDate)) }}</td>
                    <td>
                      <div class="dropdown">
                        <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" id="actionDropdown"
                          data-bs-toggle="dropdown" aria-expanded="false">
                          Select Action
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="actionDropdown">
                          <li><a class="dropdown-item" href="#" @click.prevent="showTraceHistory(request)">Trace
                              History</a></li>
                          <li v-if="request.status !== 'closed'">
                            <a class="dropdown-item" href="#" @click.prevent="showReplyModal(request)">Reply</a>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Trace History Modal -->
    <div class="modal fade" id="traceHistoryModal" tabindex="-1" ref="traceHistoryModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Trace History</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <ul class="list-group">
              <li v-for="(event, index) in showHistory" :key="index" class="list-group-item">
                <strong>{{ formatDate(event.date) }}:</strong> {{ event.description }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Reply Modal -->
    <div class="modal fade" id="replyModal" tabindex="-1" ref="replyModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Reply to Request</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="replyMessage" class="form-label">Your Message</label>
              <textarea v-model="replyMessage" class="form-control" id="replyMessage" rows="3"></textarea>
            </div>
            <button @click="sendReply" class="btn btn-primary">Send Reply</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { Modal } from 'bootstrap';
import { HelpdeskRequestsWithHistory } from '../../data/helpdesk';
import { formatDate } from '../../utils/date';

export default {
  name: 'UserHelpdesk',
  setup() {
    const newRequest = ref({ subject: '', description: '', attachment: null });
    const requests = HelpdeskRequestsWithHistory;
    const statusFilter = ref('all');
    const showHistory = ref(null);
    const replyMessage = ref('');
    const currentRequest = ref(null);
    const traceHistoryModal = ref(null);
    const replyModal = ref(null);

    const filteredRequests = computed(() => {
      if (statusFilter.value === 'all') {
        return requests;
      }
      return requests.filter(request => request.status === statusFilter.value);
    });

    const calculateExpiration = (submissionDate) => {
      const date = new Date(submissionDate);
      date.setDate(date.getDate() + 30);
      return formatDate(date);
    };

    const submitRequest = () => {
      // Handle form submission
      console.log('Request submitted:', newRequest.value);
      resetForm();
    };

    const resetForm = () => {
      newRequest.value = { subject: '', description: '', attachment: null };
    };

    const handleFileUpload = (event) => {
      newRequest.value.attachment = event.target.files[0];
    };

    const showTraceHistory = (request) => {
      showHistory.value = request.history;
      new Modal(traceHistoryModal.value).show();
    };

    const showReplyModal = (request) => {
      currentRequest.value = request;
      replyMessage.value = '';
      new Modal(replyModal.value).show();
    };

    const sendReply = () => {
      if (!replyMessage.value.trim()) return;

      // Handle sending reply
      console.log('Reply sent:', replyMessage.value);
      new Modal(replyModal.value).hide();
      replyMessage.value = '';
    };

    return {
      newRequest,
      requests,
      statusFilter,
      filteredRequests,
      showHistory,
      replyMessage,
      currentRequest,
      traceHistoryModal,
      replyModal,
      submitRequest,
      resetForm,
      handleFileUpload,
      formatDate,
      calculateExpiration,
      showTraceHistory,
      showReplyModal,
      sendReply,
    };
  },
};
</script>
