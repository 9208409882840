<template>
    <div class="container">
        <!-- Tabs -->
        <ul class="nav nav-tabs" id="userHelpdeskTabs" role="tablist">
            <li class="nav-item" role="presentation">
                <a class="nav-link active" id="submit-request-tab" data-bs-toggle="tab" href="#submit-request"
                    role="tab" aria-controls="submit-request" aria-selected="true">Request Submission</a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="review-requests-tab" data-bs-toggle="tab" href="#review-requests" role="tab"
                    aria-controls="review-requests" aria-selected="false">Review Requests</a>
            </li>
        </ul>

        <!-- Tab Content -->
        <div class="tab-content" id="userHelpdeskTabsContent">
            <!-- Request Submission Tab -->
            <div class="tab-pane fade show active" id="submit-request" role="tabpanel"
                aria-labelledby="submit-request-tab">
                <div class="card mb-4">
                    <div class="card-body">
                        <h5 class="card-title">Submit a Help Request</h5>
                        <form @submit.prevent="submitRequest">
                            <div class="mb-3">
                                <label for="serviceDescription" class="form-label">Service Description</label>
                                <textarea v-model="requestForm.serviceDescription" class="form-control"
                                    id="serviceDescription" rows="3" required></textarea>
                            </div>
                            <div class="mb-3">
                                <label for="location" class="form-label">Location</label>
                                <input v-model="requestForm.location" type="text" class="form-control" id="location"
                                    required />
                            </div>
                            <div class="mb-3">
                                <label for="category" class="form-label">Category</label>
                                <input v-model="requestForm.category" type="text" class="form-control" id="category"
                                    required />
                            </div>
                            <div class="mb-3">
                                <label for="media" class="form-label">Upload Media (Optional)</label>
                                <input type="file" @change="handleFileUpload('media')" class="form-control" id="media"
                                    accept=".png, .jpg, .jpeg, .mkv, .mp3" />
                            </div>
                            <div class="mb-3">
                                <label for="proposalDoc" class="form-label">Upload Proposal Doc</label>
                                <input type="file" @change="handleFileUpload('proposalDoc')" class="form-control"
                                    id="proposalDoc" accept=".pdf, .doc, .docx, .png, .jpg, .jpeg" />
                            </div>
                            <button type="submit" class="btn btn-primary me-2">Submit</button>
                            <button type="button" @click="resetForm" class="btn btn-secondary">Reset</button>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Review Requests Tab -->
            <div class="tab-pane fade" id="review-requests" role="tabpanel" aria-labelledby="review-requests-tab">
                <!-- Search Bar -->
                <div class="my-4">
                    <input v-model="searchQuery" type="text" class="form-control" placeholder="Search requests..."
                        title="Search by UID, Status, or Submission Date" data-bs-toggle="tooltip" />
                </div>

                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Review Service/Ad Listing Requests</h5>
                        <div class="mb-3">
                            <label for="statusFilter" class="form-label">Filter by Status</label>
                            <select v-model="statusFilter" @change="filterRequests" class="form-select"
                                id="statusFilter">
                                <option value="all">All</option>
                                <option value="approved">Approved</option>
                                <option value="pending">Pending</option>
                                <option value="proposed">Proposed</option>
                                <option value="rejected">Rejected</option>
                            </select>
                        </div>
                        <div class="table-responsive" style="max-height: 400px; overflow-y: auto;">
                            <table class="table table-striped table-bordered">
                                <thead class="sticky-top" style="border: 2px solid #dee2e6;">
                                    <tr>
                                        <th>Request UID</th>
                                        <th>Submission Date</th>
                                        <th>Status</th>
                                        <th>Expiration</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(request, index) in paginatedRequests" :key="index">
                                        <td>{{ request.uid }}</td>
                                        <td>{{ formatDate(request.submissionDate) }}</td>
                                        <td>{{ request.status }}</td>
                                        <td>{{ formatDate(request.expirationDate) }}</td>
                                        <td>
                                            <div class="dropdown">
                                                <button class="btn btn-secondary btn-sm dropdown-toggle" type="button"
                                                    id="actionDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Select Action
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="actionDropdown">
                                                    <li><a class="dropdown-item" href="#"
                                                            @click.prevent="showTraceHistory(request)">Trace History</a></li>
                                                    <li><a class="dropdown-item" href="#"
                                                            v-if="request.status === 'pending'"
                                                            @click.prevent="showFollowUpModal(request)">Follow-up</a></li>
                                                    <li><a class="dropdown-item" href="#"
                                                            v-if="request.status === 'proposed' || request.status === 'Proposed'"
                                                            @click.prevent="pay(request.uid)">Pay</a></li>
                                                    <li>
                                                        <a v-if="request.status === 'rejected' || request.status === 'proposed'"
                                                            class="dropdown-item" href="#"
                                                            @click.prevent="showReplyModal(request)">Reply
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- Pagination -->
                        <pagination :currentPage="currentPage" :totalPages="totalPages" @page-changed="changePage" />
                    </div>
                </div>
            </div>
        </div>

        <!-- Trace History Modal -->
        <div class="modal fade" id="traceHistoryModal" tabindex="-1" ref="traceHistoryModal">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Trace History</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <ul class="list-group">
                            <li v-for="(event, index) in traceHistory" :key="index" class="list-group-item">
                                <strong>{{ formatDate(event.date) }}:</strong> {{ event.description }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- Follow-up Modal -->
        <div class="modal fade" id="followUpModal" tabindex="-1" ref="followUpModal">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Follow-up</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div v-for="(message, index) in followUpMessages" :key="index">
                            <p><strong>{{ formatDate(message.date) }}:</strong> {{ message.text }}</p>
                        </div>
                        <textarea v-model="newMessage" class="form-control mt-3" rows="3"
                            placeholder="Type your message here..."></textarea>
                        <button @click="sendFollowUp" class="btn btn-primary mt-2">Send</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Payment Modal -->
        <div class="modal fade" id="paymentModal" tabindex="-1" ref="paymentModal">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Payment</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p>Please complete the payment process for the request <strong>{{ currentRequest?.uid
                                }}</strong>.</p>
                        <button @click="openPaymentPopup" class="btn btn-primary mt-2">Proceed to Payment</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Reply Modal -->
        <div class="modal fade" id="replyModal" tabindex="-1" ref="replyModal">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Reply to Request</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div v-for="(message, index) in replyMessages" :key="index">
                            <p><strong>{{ formatDate(message.date) }}:</strong> {{ message.text }}</p>
                        </div>
                        <textarea v-model="replyMessage" class="form-control mt-3" rows="3"
                            placeholder="Type your reply here..."></textarea>
                        <button @click="sendReply" class="btn btn-primary mt-2">Send Reply</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { ref, computed } from 'vue';
import { Modal, Tooltip } from 'bootstrap';

export default {
    name: 'UserHelpdesk',
    mounted() {
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });

    },
    setup() {
        const searchQuery = ref('');
        const requestForm = ref({
            serviceDescription: '',
            location: '',
            category: '',
            media: null,
            proposalDoc: null
        });
        const requests = ref([
            // Example data
            { uid: 'REQ123', submissionDate: '2024-09-10T12:00:00Z', status: 'pending', expirationDate: '2024-09-20T12:00:00Z' },
            { uid: 'REQ124', submissionDate: '2024-09-11T12:00:00Z', status: 'approved', expirationDate: '2024-09-21T12:00:00Z' },
            { uid: 'REQ125', submissionDate: '2024-09-10T12:00:00Z', status: 'proposed', expirationDate: '2024-09-20T12:00:00Z' },
            { uid: 'REQ126', submissionDate: '2024-09-11T12:00:00Z', status: 'rejected', expirationDate: '2024-09-21T12:00:00Z' },
            { uid: 'REQ127', submissionDate: '2024-09-10T12:00:00Z', status: 'pending', expirationDate: '2024-09-20T12:00:00Z' },
            { uid: 'REQ128', submissionDate: '2024-09-11T12:00:00Z', status: 'approved', expirationDate: '2024-09-21T12:00:00Z' },
            { uid: 'REQ129', submissionDate: '2024-09-10T12:00:00Z', status: 'proposed', expirationDate: '2024-09-20T12:00:00Z' },
            { uid: 'REQ130', submissionDate: '2024-09-11T12:00:00Z', status: 'rejected', expirationDate: '2024-09-21T12:00:00Z' },
            { uid: 'REQ131', submissionDate: '2024-09-10T12:00:00Z', status: 'pending', expirationDate: '2024-09-20T12:00:00Z' },
            { uid: 'REQ132', submissionDate: '2024-09-11T12:00:00Z', status: 'approved', expirationDate: '2024-09-21T12:00:00Z' },
            { uid: 'REQ133', submissionDate: '2024-09-10T12:00:00Z', status: 'proposed', expirationDate: '2024-09-20T12:00:00Z' },
            { uid: 'REQ134', submissionDate: '2024-09-11T12:00:00Z', status: 'rejected', expirationDate: '2024-09-21T12:00:00Z' },
        ]);
        const statusFilter = ref('all');
        const traceHistory = ref([
            // Example data
            { date: '2024-09-10', description: 'Request created' },
            { date: '2024-09-12', description: 'Followed Up' },
        ]);
        const followUpMessages = ref([]);
        const replyMessages = ref([]);
        const newMessage = ref('');
        const replyMessage = ref('');
        const currentRequest = ref(null);
        const traceHistoryModal = ref(null);
        const followUpModal = ref(null);
        const paymentModal = ref(null);
        const replyModal = ref(null);

        const filteredRequests = computed(() => {
            let filtered = requests.value;

            // Filter by status
            if (statusFilter.value !== 'all') {
                filtered = filtered.filter(request => request.status === statusFilter.value);
            }

            // Filter by search query
            if (searchQuery.value) {
                const query = searchQuery.value.toLowerCase();
                filtered = filtered.filter(request =>
                    request.uid.toLowerCase().includes(query) ||
                    request.status.toLowerCase().includes(query) ||
                    formatDate(request.submissionDate).toLowerCase().includes(query)
                );
            }

            return filtered;
        });

        const formatDate = (dateString) => {
            return new Date(dateString).toLocaleDateString();
        };

        const submitRequest = () => {
            console.log('Submitting request:', requestForm.value);
            // Add backend call logic here
            resetForm();
        };

        const resetForm = () => {
            requestForm.value = {
                serviceDescription: '',
                location: '',
                category: '',
                media: null,
                proposalDoc: null
            };
        };

        const handleFileUpload = (type) => {
            // Handle file upload
        };

        const showTraceHistory = (request) => {
            currentRequest.value = request;
            // Fetch trace history from backend
            new Modal(traceHistoryModal.value).show();
        };

        const showFollowUpModal = (request) => {
            currentRequest.value = request;
            // Fetch follow-up messages from backend
            new Modal(followUpModal.value).show();
        };

        const showReplyModal = (request) => {
            currentRequest.value = request;
            // Fetch reply messages from backend
            new Modal(replyModal.value).show();
        };

        const sendFollowUp = () => {
            console.log('Sending follow-up message:', newMessage.value);
            // Add backend call logic here
        };

        const sendReply = () => {
            console.log('Sending reply message:', replyMessage.value);
            // Add backend call logic here
        };

        const openPaymentPopup = () => {
            const paymentUrl = 'https://your-payment-gateway-url.com/pay'; // Replace with your payment gateway URL
            const popupOptions = 'width=600,height=600,scrollbars=yes,resizable=yes';
            window.open(paymentUrl, 'PaymentPopup', popupOptions);
        };

        const pay = (requestId) => {
            currentRequest.value = requests.value.find(req => req.uid === requestId);

            // Ensure the modal element is correctly initialized
            const modalElement = paymentModal.value;
            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();
            } else {
                console.error('Payment modal element not found');
            }
        };



        const changePage = (page) => {
            console.log('Changing page to:', page);
            // Add pagination logic here
        };

        const paginatedRequests = computed(() => {
            // Add pagination logic here
            return filteredRequests.value;
        });

        const totalPages = computed(() => {
            // Calculate total pages for pagination
            return 1; // Example placeholder
        });




        return {
            searchQuery,
            requestForm,
            requests,
            statusFilter,
            traceHistory,
            followUpMessages,
            replyMessages,
            newMessage,
            replyMessage,
            currentRequest,
            traceHistoryModal,
            followUpModal,
            paymentModal,
            replyModal,
            submitRequest,
            resetForm,
            handleFileUpload,
            showTraceHistory,
            showFollowUpModal,
            showReplyModal,
            sendFollowUp,
            sendReply,
            pay,
            openPaymentPopup,
            changePage,
            paginatedRequests,
            totalPages,
            formatDate
        };
    }
};
</script>

<style scoped>
/* Add custom styling here if needed */
</style>