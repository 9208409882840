export const Providers = [
    {
      name: 'Service Provider A',
      location: 'Lucknow',
      address: 'Address A',
      speciality: 'Toilet Repair',
      review: '4.0',
      tat: '0.7',
      category: 'Plumbing',
      images: ['https://via.placeholder.com/150', 'https://via.placeholder.com/150'],
      phone: '1234567890',
      whatsapp: '1234567890',
      facebook: 'providerA',
      addedDate: '2023-01-15',
      active: true
    },
    {
      name: 'Service Provider B',
      location: 'Delhi',
      address: 'Address B',
      speciality: 'Wiring Installation',
      review: '4.5',
      tat: '0.5',
      category: 'Electrical',
      images: ['https://via.placeholder.com/150', 'https://via.placeholder.com/150'],
      phone: '9876543210',
      whatsapp: '9876543210',
      facebook: 'providerB',
      addedDate: '2024-09-20',
      active: false
    },
    {
      name: 'Service Provider C',
      location: 'Mumbai',
      address: 'Address C',
      speciality: 'Deep Home Cleaning',
      review: '3.9',
      tat: '0.8',
      category: 'Cleaning',
      images: ['https://via.placeholder.com/150', 'https://via.placeholder.com/150'],
      phone: '1122334455',
      whatsapp: '1122334455',
      facebook: 'providerC',
      addedDate: '2024-08-30',
      active: true
    },
    {
      name: 'Service Provider D',
      location: 'Bangalore',
      address: 'Address D',
      speciality: 'Furniture Assembly',
      review: '4.2',
      tat: '1.0',
      category: 'Carpentry',
      images: ['https://via.placeholder.com/150', 'https://via.placeholder.com/150'],
      phone: '9988776655',
      whatsapp: '9988776655',
      facebook: 'providerD',
      addedDate: '2023-02-10',
      active: false
    },
    {
      name: 'Service Provider E',
      location: 'Kolkata',
      address: 'Address E',
      speciality: 'Interior Painting',
      review: '4.3',
      tat: '1.2',
      category: 'Painting',
      images: ['https://via.placeholder.com/150', 'https://via.placeholder.com/150'],
      phone: '5566778899',
      whatsapp: '5566778899',
      facebook: 'providerE',
      addedDate: '2023-02-15',
      active: true
    },
    {
      name: 'Service Provider F',
      location: 'Chennai',
      address: 'Address F',
      speciality: 'Air Conditioning Repair',
      review: '4.1',
      tat: '1.5',
      category: 'HVAC',
      images: ['https://via.placeholder.com/150', 'https://via.placeholder.com/150'],
      phone: '4455667788',
      whatsapp: '4455667788',
      facebook: 'providerF',
      addedDate: '2023-03-01',
      active: false
    },
    {
      name: 'Service Provider G',
      location: 'Hyderabad',
      address: 'Address G',
      speciality: 'Termite Extermination',
      review: '4.0',
      tat: '1.0',
      category: 'Pest Control',
      images: ['https://via.placeholder.com/150', 'https://via.placeholder.com/150'],
      phone: '6677889900',
      whatsapp: '6677889900',
      facebook: 'providerG',
      addedDate: '2023-03-10',
      active: true
    },
    {
      name: 'Service Provider H',
      location: 'Pune',
      address: 'Address H',
      speciality: 'Refrigerator Repair',
      review: '4.2',
      tat: '0.9',
      category: 'Appliance Repair',
      images: ['https://via.placeholder.com/150', 'https://via.placeholder.com/150'],
      phone: '7788990011',
      whatsapp: '7788990011',
      facebook: 'providerH',
      addedDate: '2023-03-15',
      active: false
    },
    {
      name: 'Service Provider I',
      location: 'Ahmedabad',
      address: 'Address I',
      speciality: 'Roof Leak Repair',
      review: '4.4',
      tat: '1.3',
      category: 'Roofing',
      images: ['https://via.placeholder.com/150', 'https://via.placeholder.com/150'],
      phone: '8899001122',
      whatsapp: '8899001122',
      facebook: 'providerI',
      addedDate: '2023-03-20',
      active: true
    },
    {
      name: 'Service Provider J',
      location: 'Jaipur',
      address: 'Address J',
      speciality: 'CCTV Installation',
      review: '4.5',
      tat: '1.1',
      category: 'Security Systems',
      images: ['https://via.placeholder.com/150', 'https://via.placeholder.com/150'],
      phone: '9900112233',
      whatsapp: '9900112233',
      facebook: 'providerJ',
      addedDate: '2023-04-01',
      active: false
    },
    // Additional Providers
    {
      name: 'Service Provider K',
      location: 'Delhi',
      address: 'Address K',
      speciality: 'Yoga Instructor',
      review: '4.8',
      tat: '1.0',
      category: 'Beauty & Wellness',
      images: ['https://via.placeholder.com/150', 'https://via.placeholder.com/150'],
      phone: '1010101010',
      whatsapp: '1010101010',
      facebook: 'providerK',
      addedDate: '2023-04-10',
      active: true
    },
    {
      name: 'Service Provider L',
      location: 'Kolkata',
      address: 'Address L',
      speciality: 'Thai Restaurant',
      review: '4.6',
      tat: '0.5',
      category: 'Restaurants',
      images: ['https://via.placeholder.com/150', 'https://via.placeholder.com/150'],
      phone: '2020202020',
      whatsapp: '2020202020',
      facebook: 'providerL',
      addedDate: '2023-04-15',
      active: false
    },
    {
      name: 'Service Provider M',
      location: 'Chennai',
      address: 'Address M',
      speciality: 'Makeup Artist',
      review: '4.7',
      tat: '1.0',
      category: 'Beauty & Wellness',
      images: ['https://via.placeholder.com/150', 'https://via.placeholder.com/150'],
      phone: '3030303030',
      whatsapp: '3030303030',
      facebook: 'providerM',
      addedDate: '2023-04-20',
      active: true
    },
    {
      name: 'Service Provider N',
      location: 'Bangalore',
      address: 'Address N',
      speciality: 'Carpentry Services',
      review: '4.1',
      tat: '1.2',
      category: 'Carpentry',
      images: ['https://via.placeholder.com/150', 'https://via.placeholder.com/150'],
      phone: '4040404040',
      whatsapp: '4040404040',
      facebook: 'providerN',
      addedDate: '2023-05-01',
      active: false
    },
    {
      name: 'Service Provider O',
      location: 'Mumbai',
      address: 'Address O',
      speciality: 'Plumbing Services',
      review: '4.5',
      tat: '0.9',
      category: 'Plumbing',
      images: ['https://via.placeholder.com/150', 'https://via.placeholder.com/150'],
      phone: '5050505050',
      whatsapp: '5050505050',
      facebook: 'providerO',
      addedDate: '2023-05-10',
      active: true
    }
];
