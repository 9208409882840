<template>
  <div>
    <Header />
    <Sidebar />
    <main id="main" class="main">
      <div v-if="showPageTitle" class="pagetitle">
        <h1>{{ pageTitle }}</h1>
        <nav>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
            <li v-for="(breadcrumb, index) in breadcrumbs" :key="index" class="breadcrumb-item">
              <router-link v-if="index < breadcrumbs.length - 1" :to="breadcrumb.path">{{ breadcrumb.name
                }}</router-link>
              <span v-else>{{ breadcrumb.name }}</span>
            </li>
          </ol>
        </nav>
      </div>
      <section class="section admin-helpdesk">
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </section>
    </main>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Sidebar from '@/components/Sidebar.vue';

export default {
  components: {
    Header,
    Sidebar
  },
  computed: {
    pageTitle() {
      const meta = this.$route.meta || {};
      return meta.breadcrumb || 'Default Title';
    },
    breadcrumbs() {
      const matched = this.$route.matched;
      return matched
        .filter(route => route.meta.breadcrumb)
        .map(route => ({
          name: route.meta.breadcrumb,
          path: route.path
        }));
    },
    showPageTitle() {
      return this.breadcrumbs.some(breadcrumb => breadcrumb.name !== 'Home');
    }
  }
};
</script>

<style scoped>
.main-container {
  display: flex;
}

main {
  flex: 1;
  padding: 1rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
  {
  opacity: 0;
}
</style>
