import router from '@/router';
import { createStore } from 'vuex';

export default createStore({
  state: {
    user: JSON.parse(localStorage.getItem('user')) || null,  // Load from localStorage
    token: localStorage.getItem('token') || null,  // Load token from localStorage
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    isAdmin: (state) => state.user?.role === 'admin',
  },
  mutations: {
    SET_USER(state, userData) {
      state.user = userData;
      state.token = userData.token;
      localStorage.setItem('user', JSON.stringify(userData));  // Persist user data
      localStorage.setItem('token', userData.token);  // Persist token
    },
    LOGOUT(state) {
      state.user = null;
      state.token = null;
      localStorage.removeItem('user');  // Clear localStorage on logout
      localStorage.removeItem('token');  // Clear token
    },
  },
  actions: {
    login({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        // Dummy logic, replace with real API call
        if (credentials.username === 'admin' && credentials.password === 'password') {
          const userData = {
            username: credentials.username,
            token: 'dummy-jwt-token',
            role: 'admin',
          };
          commit('SET_USER', userData);
          resolve(userData);
        } else if (credentials.username === 'user' && credentials.password === 'password') {
          const userData = {
            username: credentials.username,
            token: 'dummy-jwt-token',
            role: 'user',
          };
          commit('SET_USER', userData);
          resolve(userData);
        } else {
          reject('Invalid credentials');
        }
      });
    },
    logout({ commit }) {
      commit('LOGOUT');
      
    },
  },
});
