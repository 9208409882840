<template>
  <div>
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
    <!-- Add button to toggle between dark and light mode -->
    <button class="theme-change-btn" @click="toggleTheme">
      <i v-if="theme === 'dark'" class="bi bi-brightness-alt-low-fill" style="color: #222;"></i>
      <i v-else class="bi bi-brightness-high" style="color: #444;"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      theme: 'light' // Default theme
    };
  },
  methods: {
    toggleTheme() {
      // Toggle theme logic
      this.theme = this.theme === 'dark' ? 'light' : 'dark';
      document.documentElement.setAttribute('data-theme', this.theme);

      // Save the user's preference to localStorage
      localStorage.setItem('theme', this.theme);
    }
  },
  mounted() {
    // Check for saved theme preference in localStorage
    const savedTheme = localStorage.getItem('theme');

    if (savedTheme) {
      this.theme = savedTheme;
      document.documentElement.setAttribute('data-theme', savedTheme);
    } else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.theme = 'dark';
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      this.theme = 'light'; // or your default theme
      document.documentElement.setAttribute('data-theme', 'light');
    }

    // Existing sidebar toggle logic
    const bodyClasses = document.body.classList;
    if (bodyClasses.contains('toggle-sidebar')) {
      bodyClasses.remove('toggle-sidebar');
    }
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Optional: Dark mode button style */
.theme-change-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 30px;
  padding: 5px 15px;
  cursor: pointer;
  font-size: 25px;
}
</style>
