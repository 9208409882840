<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body pt-3">
          <!-- Bordered Tabs -->
          <ul class="nav nav-tabs nav-tabs-bordered" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#request-tab" aria-selected="true"
                role="tab">Request</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" data-bs-toggle="tab" data-bs-target="#review-tab" aria-selected="false"
                role="tab" tabindex="-1">Review</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" data-bs-toggle="tab" data-bs-target="#manage-tab" aria-selected="false"
                role="tab" tabindex="-1">Manage</button>
            </li>
          </ul>
          <div class="tab-content pt-2">
            <!-- Request Tab -->
            <div class="tab-pane fade request-tab active show" id="request-tab" role="tabpanel">
              <h5 class="card-title">Submit a Help Request</h5>
              <form @submit.prevent="submitRequest">
                <div class="row mb-3">
                  <label for="subject" class="col-md-4 col-lg-3 col-form-label">Subject</label>
                  <div class="col-md-8 col-lg-9">
                    <input v-model="form.subject" type="text" class="form-control" id="subject">
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="description" class="col-md-4 col-lg-3 col-form-label">Description</label>
                  <div class="col-md-8 col-lg-9">
                    <textarea v-model="form.description" class="form-control" id="description"
                      style="height: 100px"></textarea>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="attachment" class="col-md-4 col-lg-3 col-form-label">Attachment (optional)</label>
                  <div class="col-md-8 col-lg-9">
                    <input ref="attachment" type="file" class="form-control" id="attachment" @change="handleFileUpload">
                  </div>
                </div>
                <div class="text-center">
                  <button type="submit" class="btn btn-primary">Submit</button>
                  <button type="reset" class="btn btn-secondary" @click="resetForm">Reset</button>
                </div>
              </form>
              <div v-if="confirmationVisible" class="alert alert-success mt-3" role="alert">
                Your request has been submitted successfully!
              </div>
            </div>

            <!-- Review Tab -->
            <div class="tab-pane fade pt-3" id="review-tab" role="tabpanel">
              <h5 class="card-title">Review Help Requests</h5>
              <div class="row mb-3">
                <label for="reviewStatusFilter" class="col-md-4 col-lg-3 col-form-label">Filter by Status</label>
                <div class="col-md-8 col-lg-9">
                  <select v-model="reviewStatusFilter" class="form-control" id="reviewStatusFilter">
                    <option value="closed">Closed</option>
                    <option value="pending">Pending</option>
                    <option value="acknowledged">Acknowledged</option>
                    <option value="expired">Expired</option>
                  </select>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Helpdesk Request ID</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Status Remarks</th>
                      <th>Reply</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="request in filteredReviewRequests" :key="request.id">
                      <td>{{ request.id }}</td>
                      <td>{{ request.date }}</td>
                      <td>{{ request.status }}</td>
                      <td>{{ request.remarks }}</td>
                      <td>
                        <button class="btn btn-primary" :disabled="request.status !== 'Pending'">Reply</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- Manage Tab -->
            <div class="tab-pane fade pt-3" id="manage-tab" role="tabpanel">
              <h5 class="card-title">Manage Help Requests</h5>
              <div class="row mb-3">
                <label for="manageStatusFilter" class="col-md-4 col-lg-3 col-form-label">Filter by Status</label>
                <div class="col-md-8 col-lg-9">
                  <select v-model="manageStatusFilter" class="form-control" id="manageStatusFilter">
                    <option value="closed">Closed</option>
                    <option value="pending">Pending</option>
                    <option value="acknowledged">Acknowledged</option>
                    <option value="expired">Expired</option>
                  </select>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Helpdesk Request ID</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Status Remarks</th>
                      <th>Reply</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="request in manageRequests" :key="request.id">
                      <td>{{ request.id }}</td>
                      <td>{{ request.date }}</td>
                      <td>
                        <select v-model="request.status" class="form-control">
                          <option value="closed">Closed</option>
                          <option value="pending">Pending</option>
                          <option value="acknowledged">Acknowledged</option>
                          <option value="expired">Expired</option>
                        </select>
                      </td>
                      <td>{{ request.remarks }}</td>
                      <td>
                        <button class="btn btn-primary" :disabled="request.status === 'Closed'">Reply</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div><!-- End Bordered Tabs -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        subject: '',
        description: '',
        attachment: null
      },
      confirmationVisible: false,
      reviewStatusFilter: 'closed',
      manageStatusFilter: 'closed',
      manageRequests: [
        {
          id: 'REQ12345',
          date: '2023-08-01',
          status: 'closed',
          remarks: 'Issue resolved'
        },
        {
          id: 'REQ12346',
          date: '2023-08-02',
          status: 'pending',
          remarks: 'Awaiting response'
        }
      ]
    };
  },
  computed: {
    filteredReviewRequests() {
      const allRequests = [
        { id: 'REQ12345', date: '2023-08-01', status: 'Closed', remarks: 'Issue resolved' },
        { id: 'REQ12346', date: '2023-08-02', status: 'Pending', remarks: 'Awaiting response' }
      ];
      return allRequests.filter(request => request.status.toLowerCase() === this.reviewStatusFilter);
    }
  },
  methods: {
    submitRequest() {
      // Handle form submission logic here
      this.confirmationVisible = true;
    },
    resetForm() {
      this.form = {
        subject: '',
        description: '',
        attachment: null
      };
    },
    handleFileUpload(event) {
      this.form.attachment = event.target.files[0];
    }
  }
};
</script>

<style scoped>
/* Add any additional styling here if needed */
</style>