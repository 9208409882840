<template>
    
      <div class="container">
        <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-2">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div class="d-flex justify-content-center py-2">
                  <a href="#" class="logo d-flex align-items-center w-auto">
                    <img src="../assets/logo.png" alt="" style="max-height: 100px;max-width: 200px;">
                    <!--span class="d-none d-lg-block">AvailIndia</span-->
                  </a>
                </div><!-- End Logo -->
  
                <div class="card mb-3">
                  <div class="card-body">
                    <div class="pb-2">
                      <h5 class="card-title text-center pb-0 fs-4">Create an Account</h5>
                      <p class="text-center small">Enter your personal details to create an account</p>
                    </div>
  
                    <form @submit.prevent="handleSubmit" class="row g-3 needs-validation" novalidate>
                      <div class="col-12">
                        <label for="name" class="form-label">Your Name</label>
                        <input type="text" v-model="name" class="form-control" id="name" required>
                        <div class="invalid-feedback">Please, enter your name!</div>
                      </div>
  
                      <div class="col-12">
                        <label for="email" class="form-label">Your Email</label>
                        <input type="email" v-model="email" class="form-control" id="email" required>
                        <div class="invalid-feedback">Please enter a valid Email address!</div>
                      </div>
  
                      <div class="col-12">
                        <label for="username" class="form-label">Username</label>
                        <div class="input-group has-validation">
                          <span class="input-group-text" id="inputGroupPrepend">@</span>
                          <input type="text" v-model="username" class="form-control" id="username" required>
                          <div class="invalid-feedback">Please choose a username.</div>
                        </div>
                      </div>
  
                      <div class="col-12">
                        <label for="password" class="form-label">Password</label>
                        <input type="password" v-model="password" class="form-control" id="password" required>
                        <div class="invalid-feedback">Please enter your password!</div>
                      </div>
  
                      <div class="col-12">
                        <label for="userType" class="form-label">User Type</label>
                        <select v-model="userType" class="form-select" id="userType" required>
                          <option value="1">Customer</option>
                          <option value="2">Vendor</option>
                          <option value="3">Admin</option>
                        </select>
                      </div>
  
                      <div class="col-12">
                        <div class="form-check">
                          <input class="form-check-input" v-model="acceptTerms" type="checkbox" id="acceptTerms" required>
                          <label class="form-check-label" for="acceptTerms">I agree and accept the <a href="#">terms and conditions</a></label>
                          <div class="invalid-feedback">You must agree before submitting.</div>
                        </div>
                      </div>
  
                      <div class="col-12">
                        <button class="btn btn-primary w-100" type="submit">Create Account</button>
                      </div>
  
                      <div class="col-12">
                        <p class="small mb-0">Already have an account? <router-link to="/login">Log in</router-link></p>
                      </div>
                    </form>
                  </div>
                </div>
  
                <div class="credits">
                  Designed by <a href="#">DesignCompanyName</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        name: '',
        email: '',
        username: '',
        password: '',
        userType: '1', // Default to Customer
        acceptTerms: false
      };
    },
    methods: {
      handleSubmit() {
        if (this.acceptTerms) {
          // Implement your registration logic here
          console.log({
            name: this.name,
            email: this.email,
            username: this.username,
            password: this.password,
            userType: this.userType
          });
        } else {
          // Handle case where terms are not accepted
          alert('You must accept the terms and conditions.');
        }
      }
    }
  };
  </script>
  
  <style scoped>
  /* Add any custom styles here */
  </style>
  