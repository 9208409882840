<template>
  <div class="row">
    <!-- Main Content Area -->
    <div class="col-lg-9 col-md-8 col-sm-12">
      <div class="row">
        <div class="pagetitle">
          <div form="">
            <div class="row mb-3">
              <div class="col-md-8">
                <div>
                  <h5 class="font-size-16 font-weight-900">Applied Filters</h5>
                  <span v-for="(filter, index) in appliedFilters" :key="index" class="badge" :class="filter.class">{{
                    filter.label }}</span>
                </div>
              </div>
              <div class="col-md-4">
                <label class="col-form-label">Sort Results By:</label>
                <select class="form-select" v-model="sortBy" @change="sortAds">
                  <option value="dateNewest">Submission Date: Newest First</option>
                  <option value="dateOldest">Submission Date: Oldest First</option>
                  <option value="status">Status</option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-12">
                <div class="d-flex justify-content-between mb-3">
                  <div>
                    <select class="form-select d-inline" v-model="selectedBulkAction" aria-label="Bulk Actions">
                      <option value="">Select Bulk Action</option>
                      <option value="approve">Approve</option>
                      <option value="reject">Reject</option>
                      <option value="delete">Delete</option>
                    </select>
                    <button class="btn btn-primary me-2" @click="performBulkAction" style="margin-top: 10px;">Apply Bulk
                      Action</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Ads Listing Table -->
      <div class="card">
        <div class="card-body table-responsive p-4" style="max-height: 400px; overflow-y: auto;">
          <table class="table table-striped">
            <thead class="sticky-top" style="border: 2px solid #dee2e6;">
              <tr>
                <th>
                  <input type="checkbox" v-model="selectAll" @change="toggleAll">
                </th>
                <th>Title</th>
                <th>Request UID</th>
                <th>Submission Date</th>
                <th>Status</th>
                <th>Expiration</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(ad, index) in filteredAds" :key="index">
                <td><input type="checkbox" v-model="selectedAds" :value="ad.id"></td>
                <td>{{ ad.title }}</td>
                <td>{{ ad.requestUid }}</td>
                <td>{{ ad.submissionDate }}</td>
                <td>{{ ad.status }}</td>
                <td>{{ ad.expiration || 'N/A' }}</td>

                <td>
                  <div class="dropdown">
                    <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" id="actionDropdown"
                      data-bs-toggle="dropdown" aria-expanded="false">
                      Select Action
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="actionDropdown">
                      <li><a class="dropdown-item" href="#" @click="openTraceHistoryModal(ad.id)">Trace History</a></li>
                      <li><a class="dropdown-item" href="#" v-if="ad.status === 'Pending'"
                          @click="openReplyModal(ad.id)">Reply</a></li>
                      <li><a class="dropdown-item" href="#" v-if="ad.status === 'Pending' || ad.status === 'Proposed'"
                          @click="openProposeAmountModal(ad.id)">Propose Amount</a></li>
                      <li><a class="dropdown-item" href="#" @click="openEditAdModal(ad.id)">Edit</a></li>
                      <li><a class="dropdown-item text-danger" href="#" @click="openDeleteAdModal(ad.id)">Delete</a>
                      </li>
                    </ul>
                  </div>
                </td>
                <!--td>
                  <button class="btn btn-primary btn-sm" @click="takeAction(ad.id)">Take Action</button>
                </td-->

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Sidebar for Filters -->
    <div class="col-lg-3 col-md-4 col-sm-12">
      <div class="card mb-4">
        <div class="card-body">
          <h5 class="card-title">Filter by Status</h5>
          <ul class="list-group">
            <li class="list-group-item">
              <input class="form-check-input me-1" type="checkbox" value="Approved" v-model="statusFilters"> Approved
            </li>
            <li class="list-group-item">
              <input class="form-check-input me-1" type="checkbox" value="Pending" v-model="statusFilters"> Pending
            </li>
            <li class="list-group-item">
              <input class="form-check-input me-1" type="checkbox" value="Proposed" v-model="statusFilters"> Proposed
            </li>
            <li class="list-group-item">
              <input class="form-check-input me-1" type="checkbox" value="Rejected" v-model="statusFilters"> Rejected
            </li>
          </ul>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Search Filters</h5>
          <div class="mb-3">
            <input type="text" class="form-control" placeholder="Search by UID" v-model="searchUid">
          </div>
          <div class="mb-3">
            <input type="date" class="form-control" v-model="startDate">
            <input type="date" class="form-control mt-2" v-model="endDate">
          </div>
        </div>
      </div>
    </div>


    <!-- Modals -->
    <ReusableModal title="Trace History" modalId="traceHistoryModal">
      <template #body>
        <ul>
          <li v-for="(history, index) in traceHistory" :key="index">
            <strong>{{ history.date }}:</strong> {{ history.event }}
          </li>
        </ul>
      </template>
    </ReusableModal>

    <ReusableModal title="Reply" modalId="replyModal" :onConfirm="sendReply">
      <template #body>
        <textarea v-model="replyMessage" class="form-control" rows="4" placeholder="Type your reply here"></textarea>
      </template>
    </ReusableModal>

    <ReusableModal title="Propose Amount" modalId="proposeAmountModal" :onConfirm="submitProposeAmount">
      <template #body>
        <input v-model="proposedAmount" type="number" class="form-control" placeholder="Enter proposed amount">
      </template>
    </ReusableModal>

    <ReusableModal title="Edit Ad" modalId="editAdModal" :onConfirm="submitEditAd">
      <template #body>
        <form @submit.prevent="submitEditAd">
          <div class="mb-3">
            <label for="adTitle" class="form-label">Ad Title</label>
            <input v-model="editedAd.title" type="text" class="form-control" id="adTitle"
              placeholder="Enter new ad title">
          </div>
          <div class="mb-3">
            <label for="adDescription" class="form-label">Ad Description</label>
            <textarea v-model="editedAd.description" class="form-control" id="adDescription" rows="4"
              placeholder="Enter new ad description"></textarea>
          </div>
        </form>
      </template>
    </ReusableModal>

    <ReusableModal title="Delete Ad" modalId="deleteAdModal" :onConfirm="confirmDeleteAd">
      <template #body>
        <p>Are you sure you want to delete ad ID {{ selectedAdId }}?</p>
      </template>
    </ReusableModal>

  </div>
</template>

<script>
import { Modal } from 'bootstrap';
import ReusableModal from '@/components/modals/ReusableModal.vue';

export default {
  components: {
    ReusableModal
  },
  data() {
    return {
      ads: [
        // Sample Data
        { id: '1', title: 'Ad 1', requestUid: 'UID123', submissionDate: '2024-09-01', status: 'Pending', expiration: '2024-12-01' },
        { id: '2', title: 'Ad 2', requestUid: 'UID456', submissionDate: '2024-09-02', status: 'Approved', expiration: '2024-12-02' },
        // Add more sample data as needed
      ],
      selectedAds: [],
      selectedBulkAction: '',
      sortBy: 'dateNewest',
      statusFilters: [],
      searchUid: '',
      startDate: '',
      endDate: '',
      selectedAdId: null,
      selectAll: false,
      currentAction: null,
      selectedAdId: null,
      traceHistory: [],
      replyMessage: '',
      proposedAmount: '',
      editedAd: {},
    };
  },
  methods: {
    submitProposeAmount() {
      console.log(`Proposed amount for ad ${this.selectedAdId}`);
      // Implement propose amount logic here
    },
  },
  computed: {
    filteredAds() {
      return this.ads
        .filter(ad => !this.statusFilters.length || this.statusFilters.includes(ad.status))
        .filter(ad => !this.searchUid || ad.requestUid.includes(this.searchUid))
        .filter(ad => (!this.startDate || new Date(ad.submissionDate) >= new Date(this.startDate)) && (!this.endDate || new Date(ad.submissionDate) <= new Date(this.endDate)));
    },
    appliedFilters() {
      return [
        ...this.statusFilters.map(status => ({ label: `Status: ${status}`, class: 'badge-info' })),
        this.searchUid && { label: `Search UID: ${this.searchUid}`, class: 'badge-primary' },
        this.startDate && this.endDate && { label: `Date Range: ${this.startDate} to ${this.endDate}`, class: 'badge-secondary' }
      ].filter(Boolean);
    }
  },
  methods: {
    // Trace History Modal: Fetch history and display it
    openTraceHistoryModal(id) {
      this.selectedAdId = id;
      this.traceHistory = this.getAdHistory(id); // Assuming you have an API call or function
      this.showModal('traceHistoryModal');
    },
    getAdHistory(id) {
      // Example of mock data for trace history
      return [
        { date: '2024-09-01', event: 'Submitted' },
        { date: '2024-09-05', event: 'Reviewed' },
      ];
    },

    // Reply Modal: Reply to the selected ad
    openReplyModal(id) {
      this.selectedAdId = id;
      this.replyMessage = ''; // Reset the reply message
      this.showModal('replyModal');
    },
    sendReply() {
      if (!this.replyMessage.trim()) {
        alert('Reply message cannot be empty!');
        return;
      }
      console.log(`Reply sent for ad ${this.selectedAdId}: ${this.replyMessage}`);
      // Implement actual API logic to send the reply message here
      this.hideModal('replyModal');
    },

    // Propose Amount Modal: Submit a proposed amount
    openProposeAmountModal(id) {
      this.selectedAdId = id;
      this.proposedAmount = ''; // Reset proposed amount
      this.showModal('proposeAmountModal');
    },
    submitProposeAmount() {
      if (!this.proposedAmount || isNaN(this.proposedAmount)) {
        alert('Please enter a valid amount!');
        return;
      }
      console.log(`Proposed amount for ad ${this.selectedAdId}: ${this.proposedAmount}`);
      // Implement actual logic to propose an amount here
      this.hideModal('proposeAmountModal');
    },

    // Edit Ad Modal: Fetch and display ad details for editing
    editAd(adId) {
      // Find the selected ad by its ID
      const ad = this.ads.find(ad => ad.id === adId);

      if (ad) {
        this.selectedAdId = adId;
        // Clone the ad data to avoid directly mutating the original
        this.editedAd = { ...ad };

        // Show the edit modal (assuming Bootstrap modal is used)
        const modal = new bootstrap.Modal(document.getElementById('editAdModal'));
        modal.show();
      } else {
        console.error('Ad not found!');
      }
    },
    openEditAdModal(id) {
      this.selectedAdId = id;
      this.editedAd = this.getAdById(id); // Get the ad data to be edited
      this.showModal('editAdModal');
    },
    submitEditAd() {
      // Find the ad to update
      const adIndex = this.ads.findIndex(ad => ad.id === this.selectedAdId);

      if (adIndex !== -1) {
        // Update the ad details
        this.$set(this.ads, adIndex, { ...this.editedAd });

        // Close the modal
        const modal = bootstrap.Modal.getInstance(document.getElementById('editAdModal'));
        modal.hide();
      } else {
        console.error('Failed to update ad: Ad not found!');
      }
    },
    getAdById(id) {
      return this.ads.find(ad => ad.id === id) || null;
    },

    // Delete Ad Modal: Confirm and delete the selected ad
    openDeleteAdModal(id) {
      this.selectedAdId = id;
      this.showModal('deleteAdModal');
    },
    confirmDeleteAd() {
      // Perform the delete operation
      this.deleteAd(this.selectedAdId);
      // Hide the modal after deletion
      this.hideModal('deleteAdModal');
    },
    deleteAd(id) {
      this.ads = this.ads.filter(ad => ad.id !== id);
      console.log(`Deleted ad ${id}`);
      // Implement actual deletion logic here (API call or update state)
    },

    // Modal Show and Hide Logic
    showModal(modalId) {
      const modalElement = document.getElementById(modalId);
      if (modalElement) {
        const modal = new Modal(modalElement);
        modal.show();
      }
    },
    hideModal(modalId) {
      const modalElement = document.getElementById(modalId);
      if (modalElement) {
        const modalInstance = Modal.getInstance(modalElement);
        if (modalInstance) {
          modalInstance.hide();
        }
      }
    },

    //Sorting and other functions
    sortAds() {
      switch (this.sortBy) {
        case 'dateNewest':
          this.ads.sort((a, b) => new Date(b.submissionDate) - new Date(a.submissionDate));
          break;
        case 'dateOldest':
          this.ads.sort((a, b) => new Date(a.submissionDate) - new Date(b.submissionDate));
          break;
        case 'status':
          this.ads.sort((a, b) => a.status.localeCompare(b.status));
          break;
        default:
          break;
      }
    },
    performBulkAction() {
      switch (this.selectedBulkAction) {
        case 'approve':
          this.selectedAds.forEach(id => this.updateAdStatus(id, 'Approved'));
          break;
        case 'reject':
          this.selectedAds.forEach(id => this.updateAdStatus(id, 'Rejected'));
          break;
        case 'delete':
          this.selectedAds.forEach(id => this.deleteAd(id));
          break;
        default:
          break;
      }
      this.selectedBulkAction = '';
      this.selectedAds = [];
    },
    takeAction(id) {
      console.log(`Action taken on ad ${id}`);
      // Implement your custom action logic here
    },
    updateAdStatus(id, status) {
      const ad = this.ads.find(ad => ad.id === id);
      if (ad) {
        ad.status = status;
        console.log(`Updated ad ${id} to ${status}`);
        // Implement update status logic here
      }
    },
    toggleAll(event) {
      this.selectedAds = event.target.checked ? this.ads.map(ad => ad.id) : [];
    }
  }
};
</script>


<style scoped>
.card-body {
  padding: 1.5rem;
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.table th,
.table td {
  vertical-align: middle;
}

.btn {
  margin: 2px auto;
}
</style>
