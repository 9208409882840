/* eslint-disable */
<template>
  <header id="header" class="header fixed-top d-flex align-items-center">
    <div class="d-flex align-items-center justify-content-between">
      <router-link to="/" class="logo d-flex align-items-center">
        <img src="../assets/logo.png" alt="Logo" style="max-height: 100px; max-width: 150px;">
        <!--span class="d-none d-lg-block">Avail India</span-->
      </router-link>
      <i class="bi bi-list toggle-sidebar-btn" @click="toggleSidebar"></i>
    </div><!-- End Logo -->

    <div class="search-bar">
      <form class="search-form d-flex align-items-center" method="POST" action="#">
        <input type="text" name="query" placeholder="Search" title="Enter search keyword">
        <button type="submit" title="Search"><i class="bi bi-search"></i></button>
      </form>
    </div><!-- End Search Bar -->

    <nav class="header-nav ms-auto">
      <ul class="d-flex align-items-center">
        <li class="nav-item d-block d-lg-none">
          <a class="nav-link nav-icon search-bar-toggle" href="#">
            <i class="bi bi-search"></i>
          </a>
        </li><!-- End Search Icon -->

        <!--li class="nav-item dropdown">
          <a class="nav-link nav-icon" href="#" @click.prevent="toggleNotifications" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-bell"></i>
            <span class="badge bg-primary badge-number">2</span>
          </a><!-- End Notification Icon -->

        <!--ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
            <li class="dropdown-header">
              You have 2 unread notifications
              <a href="#"><span class="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>

            <li class="notification-item">
              <i class="bi bi-exclamation-circle text-warning"></i>
              <div>
                <h4>Support</h4>
                <p>You have received a response on your Ticket #3250</p>
                <p>30 min. ago</p>
              </div>
            </li>

            <li>
              <hr class="dropdown-divider">
            </li>

            <li class="notification-item">
              <i class="bi bi-x-circle text-danger"></i>
              <div>
                <h4>Service Provider</h4>
                <p>You might want to check message received from "Armadilo Furniture Shop".</p>
                <p>1 hr. ago</p>
              </div>
            </li>
          </ul><!-- End Notification Dropdown Items -->
        <!--/li><!-- End Notification Nav -->

        <li v-if="isAuthenticated" class="nav-item dropdown pe-3">
          <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown"
            aria-expanded="false">
            <img
              src="https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1722643200&semt=ais_hybrid"
              alt="Profile" class="rounded-circle">
            <span class="d-none d-md-block dropdown-toggle ps-2">{{ username }}</span>
          </a><!-- End Profile Image Icon -->

          <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
            <li class="dropdown-header">
              <h6>{{ username }}</h6>
              <!--span>{{ userRole }}</span-->
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>

            <li v-if="!isAdmin">
              <router-link class="dropdown-item d-flex align-items-center" to="/profile">
                <i class="bi bi-person"></i>
                <span>My Profile</span>
              </router-link>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>

            <li v-if="!isAdmin">
              <router-link class="dropdown-item d-flex align-items-center" to="/user-help">
                <i class="bi bi-question-circle"></i>
                <span>Need Help?</span>
              </router-link>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>

            <li>
              <a class="dropdown-item d-flex align-items-center" href="#" @click.prevent="handleLogout">
                <i class="bi bi-box-arrow-right"></i>
                <span>Sign Out</span>
              </a>
            </li>
          </ul><!-- End Profile Dropdown Items -->
        </li><!-- End Profile Nav -->

        <router-link v-else class="nav-link nav-profile d-flex align-items-center pe-0" to="/login">
          <span class="mx-4">
            <i class="bi bi-box-arrow-in-right"></i>
            Login/Register
          </span>
        </router-link>

      </ul>
    </nav><!-- End Icons Navigation -->
  </header>
</template>

<script>
import 'bootstrap';
import { mapGetters } from 'vuex';
import router from '@/router';

export default {
  name: 'Header',
  computed: {
    ...mapGetters(['isAuthenticated', 'isAdmin']),
    username() {
      return this.$store.state.user?.username || 'Guest';
    },
    userRole() {
      return this.isAdmin ? 'Admin' : 'Customer';
    }
  },
  methods: {
    toggleSidebar() {
      document.body.classList.toggle("toggle-sidebar");
    },
    async handleLogout() {
      try {
        await this.$store.dispatch('logout');
        router.push('/'); // Redirect to home page after logout
      } catch (error) {
        console.error('Logout failed:', error);
        // Optionally handle the error, e.g., show a notification
      }
    }
  }
};
</script>

<style scoped>
/* Scoped styles for the header component */
</style>