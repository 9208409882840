<template>
  <div class="homepage">
    <!-- Banner Section -->
    <div class="banner-section">
      <div class="overlay">
        <div class="col-12 mt-5">
          <h1 class="banner-title">Find the Service You Need</h1>
          <form @submit.prevent="search">
            <div class="search-box">
              <input v-model="searchTerm" type="text" placeholder="What are you looking for?" required
                class="search-input" />
              <div class="row">
                <div class="col-auto filters">
                  <select v-model="location" class="location-filter">
                    <option value="" disabled selected>Select Location</option>
                    <option>Lucknow</option>
                    <option>Delhi</option>
                    <option>Mumbai</option>
                    <option>Bangalore</option>
                    <option>Kolkata</option>
                    <option>Chennai</option>
                  </select>
                </div>

                <div class="col-auto filters">
                  <select v-model="category" class="category-filter">
                    <option value="" disabled selected>Select Category</option>
                    <option>Plumbing</option>
                    <option>Electrical</option>
                    <option>Cleaning</option>
                    <option>Carpentry</option>
                    <option>Painting</option>
                    <option>Landscaping</option>
                    <option>HVAC</option>
                    <option>Pest Control</option>
                    <option>Appliance Repair</option>
                    <option>Roofing</option>
                    <option>Security Systems</option>
                    <option>IT Support</option>
                  </select>
                </div>
                <div class="col-12 col-md-auto d-flex justify-content-center justify-content-md-start">
                  <button type="submit" class="btn-search">Search</button>
                </div>
              </div>
            </div>
          </form>



        </div>
        <div class="col-12 d-flex text-center text-md-start">
          <router-link to="/service-ad-list" class="cta-link">
            <h4 class="cta-link-text">Need a listing?</h4>
          </router-link>
        </div>
      </div>

      <div class="slider">
        <img :src="bannerImages[currentImage]" alt="Banner Image" class="banner-image" />
      </div>

    </div>



    <!-- Promotional Section -->
    <div class="promotional-section">
      <h2 class="section-title">Promotions</h2>
      <div class="promotional-content">

        <div class="banner-section">
          <div class="slider">
            <img :src="middleBannerImages[currentMiddleImage]" alt="Middle Banner Image" class="promo-image" />
          </div>
        </div>

        <!--Older Promo div class="promo-item">
          <img src="https://via.placeholder.com/150" alt="Promo 1" />
          <p>Trusted Professionals</p>
          <button class="promo-cta-btn">Learn More</button>
        </div>
        <div class="promo-item">
          <img src="https://via.placeholder.com/150" alt="Promo 2" />
          <p>Wide Range of Services</p>
          <button class="promo-cta-btn">Learn More</button>
        </div>
        <div class="promo-item">
          <img src="https://via.placeholder.com/150" alt="Promo 3" />
          <p>Easy Booking</p>
          <button class="promo-cta-btn">Learn More</button>
        </div-->


      </div>
    </div>



    <!-- Contact Us Form Section -->
    <div class="contact-section">
      <h2 class="section-title">Contact Us</h2>
      <form @submit.prevent="submitForm" class="contact-form">
        <div class="form-group">
          <label for="emailOrMobile">Email/Mobile</label>
          <input v-model="contactForm.emailOrMobile" type="text" id="emailOrMobile"
            placeholder="Enter your email or mobile number" required class="form-input" />
        </div>

        <div class="form-group">
          <label for="description">Request Description</label>
          <textarea v-model="contactForm.description" id="description" rows="5" placeholder="Describe your request"
            required class="form-textarea"></textarea>
        </div>

        <div class="form-group">
          <label for="media">Media Upload (Optional)</label>
          <input type="file" @change="handleFileUpload" id="media" accept=".png, .jpg, .jpeg, .gif, .pdf"
            class="form-file" />
          <!-- Added file preview for better UX -->
          <div v-if="contactForm.media" class="file-preview">
            <p>File uploaded: {{ contactForm.media.name }}</p>
          </div>
        </div>

        <div class="form-actions">
          <button type="submit" class="btn-submit">Submit</button>
          <button type="button" @click="resetForm" class="btn-reset">Reset</button>
        </div>

        <div v-if="message" class="form-message" :class="{ 'success': success, 'error': !success }">
          {{ message }}
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { RouterLink } from 'vue-router';
import router from '@/router';

const searchTerm = ref('');
const location = ref('');
const category = ref('');

const plumbingImage = require('../assets/plumbing.jpg');
const carService = require('../assets/car-service.jpg');
const lady = require('../assets/promotion/lady.jpg');
const clock = require('../assets/promotion/clock.jpg');

export default {
  setup() {
    const search = () => {
      const query = {
        searchterm: searchTerm.value,
        location: location.value,
        category: category.value,
      };

      router.push({ path: '/search', query });
    };

    const bannerImages = [
      plumbingImage,
      carService,
    ];
    const middleBannerImages = [lady, clock];

    const currentImage = ref(0);
    const currentMiddleImage = ref(0);

    // Auto-slider logic
    setInterval(() => {
      currentImage.value = (currentImage.value + 1) % bannerImages.length;
    }, 3000);

    setInterval(() => {
      currentMiddleImage.value = (currentMiddleImage.value + 1) % middleBannerImages.length;
    }, 3000);

    const contactForm = ref({
      emailOrMobile: '',
      description: '',
      media: null,
    });

    const message = ref('');
    const success = ref(true);

    const handleFileUpload = (event) => {
      contactForm.value.media = event.target.files[0];
    };

    const submitForm = () => {
      try {
        console.log('Form submitted:', contactForm.value);
        message.value = 'Your request has been submitted successfully.';
        success.value = true;
        resetForm();
      } catch (error) {
        message.value = 'There was an error submitting your request.';
        success.value = false;
      }
    };

    const resetForm = () => {
      contactForm.value = {
        emailOrMobile: '',
        description: '',
        media: null,
      };
      message.value = '';
    };

    return {
      searchTerm,
      location,
      category,
      search,
      bannerImages,
      currentImage,
      middleBannerImages,
      currentMiddleImage,
      contactForm,
      handleFileUpload,
      submitForm,
      resetForm,
      message,
      success,
    };
  },
};
</script>

<style scoped>
.homepage {
  font-family: 'Nunito', sans-serif;
}

/* Banner Section */
.banner-section {
  margin: -20px;
  margin-left: -30px;
  margin-right: -30px;
  position: relative;
  height: 500px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .overlay {
    flex-direction: row;
    justify-content: space-between;
  }

  .banner-title {
    padding-left: 20px;
    font-size: 1.8em !important;
  }

  .search-box{
    align-items: center;
  }

  .search-input {
    width: 350px;
  }

  .filters {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .btn-search {
    width: 100px;
    margin-top: 20px;
  }
  .cta-link{
    padding: 10px 25px;
  }
  .cta-link-text{
    font-size: 15px !important;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5%;
  background: rgba(0, 0, 0, 0.6);
  color: white;
}


.banner-title {
  font-size: 36px;
  margin-bottom: 20px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.search-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.search-input {
  padding: 10px;
  margin-bottom: 10px;
  max-width: 460px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
}

.filters {
  display: flex;
  gap: 10px;
}

.btn-search {
  align-self: flex-start;
}

.location-filter,
.category-filter {
  background-color: transparent;
  color: white;
  padding: 10px;
  border: 0px;
  font-size: 16px;
}

.location-filter option,
.category-filter option {
  background-color: white;
  color: black;
}

.category-filter select {
  color: rgb(0, 0, 0);
}

.btn-search {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.cta-link {
  font-family: "Playfair Display", serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  text-decoration: none;
  color: #ffffff;
  background-color: transparent;
  border: 2px solid #c3c3c3;
  padding: 12px 30px;
  border-radius: 30px;
  transition: all 0.3s ease;
  overflow: hidden;
  margin-top: 100px;
}

.slider {
  height: 100%;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Promotional Section */
.promotional-section {
  margin-top: 50px;
  padding: 50px 20px;
  text-align: center;
}

.section-title {
  font-size: 32px;
  margin-bottom: 40px;
}

.promotional-content {
  display: block;
}

.promo-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.promo-item {
  flex: 1;
  text-align: center;
}

.promo-item img {
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}

.promo-item p {
  font-size: 18px;
}

.promo-cta-btn {
  padding: 8px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.promo-cta-btn:hover {
  background-color: #0056b3;
}

/* Contact Section */
.contact-section {
  padding: 50px 20px;
  text-align: center;
}

.contact-form {
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
}

.section-title {
  align-items: center;
}

.contact-form {
  max-width: 600px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
}

.form-input,
.form-textarea,
.form-file {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.form-textarea {
  resize: none;
}

.file-preview {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
}

.form-actions {
  display: flex;
  justify-content: space-between;
}

.btn-submit,
.btn-reset {
  padding: 12px 20px;
  border: none;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-submit {
  background-color: #24c234;
  color: white;
}

.btn-reset {
  background-color: #e74c3c;
  color: white;
}

.form-message {
  margin-top: 20px;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
}

.form-message.success {
  background-color: #2ecc71;
  color: white;
}

.form-message.error {
  background-color: #e74c3c;
  color: white;
}

.form-message i {
  margin-right: 10px;
}
</style>
