<template>
  <div class="row">
    <!-- Users List -->
    <div class="col-lg-9 col-md-8">
      <div class="row mb-3">
        <div class="col-md-6">
          <label class="col-form-label">Sort By:</label>
          <select class="form-select" v-model="sortOption">
            <option value="username">Username</option>
            <option value="createdAt">Creation Date</option>
          </select>
        </div>
      </div>

      <!-- Header for User Details and Active -->
      <div class="row bg-light mt-3 p-2 border list-header">
        <div class="col">
          <h5 class="mb-0">User Details</h5>
        </div>
        <div class="col text-end">
          <h5 class="mb-0">Active</h5>
        </div>
      </div>

      <!-- User List -->
      <div class="list-group">
        <div class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
          v-for="user in paginatedUsers" :key="user.id" @click="selectUser(user)">
          <div>
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{ user.username }}</h5>
              <small>{{ formatDate(user.createdAt) }}</small>
            </div>
            <p class="mb-1">{{ user.email }}</p>
            <small>Role: {{ user.role }}</small>
          </div>

          <div class="form-check form-switch ms-3">
            <input class="form-check-input" type="checkbox" v-model="user.active"
              @click.stop="changeStatus(user, user.active)" />
          </div>
        </div>
      </div>

      <!-- Pagination -->
      <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" @click="changePage(currentPage - 1)" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          <li v-for="page in totalPages" :key="page" class="page-item" :class="{ active: page === currentPage }">
            <a class="page-link" @click="changePage(page)">{{ page }}</a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" @click="changePage(currentPage + 1)" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>

    <!-- User Filters Sidebar -->
    <div class="col-lg-3 col-md-4">
      <div class="card mb-3 shadow-sm">
        <div class="card-body">
          <h5 class="card-title mb-3">Role Filters</h5>
          <ul class="list-group">
            <li class="list-group-item d-flex align-items-center">
              <input class="form-check-input me-2" type="checkbox" v-model="filters.roles.admin" id="filter-admin" />
              <label for="filter-admin" class="form-check-label">Admin</label>
            </li>
            <li class="list-group-item d-flex align-items-center">
              <input class="form-check-input me-2" type="checkbox" v-model="filters.roles.user" id="filter-user" />
              <label for="filter-user" class="form-check-label">User</label>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- User Details Modal (Controlled by Vue) -->
    <div v-if="showModal" class="modal-backdrop">
      <div class="modal fade show d-block" tabindex="-1" aria-labelledby="userModalLabel">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ selectedUser.username }}'s Details</h5>
              <button type="button" class="btn-close" @click="closeModal"></button>
            </div>
            <div class="modal-body">
              <!-- Acknowledgement Message -->
              <div v-if="showMessage" class="alert alert-success" role="alert">
                Changes saved successfully!
              </div>

              <!-- Validation Errors -->
              <div v-if="validationErrors.length" class="alert alert-danger">
                <ul>
                  <li v-for="(error, index) in validationErrors" :key="index">{{ error }}</li>
                </ul>
              </div>

              <!-- User Form -->
              <div class="form-group">
                <label>Username</label>
                <input type="text" class="form-control" v-model="selectedUser.username" />
              </div>
              <div class="form-group">
                <label>Email</label>
                <input type="email" class="form-control" v-model="selectedUser.email" />
              </div>
              <div class="form-group">
                <label>Role</label>
                <input type="text" class="form-control" v-model="selectedUser.role" />
              </div>
              <div class="form-group">
                <label>Phone Number</label>
                <input type="text" class="form-control" v-model="selectedUser.profile.phoneNumber" />
              </div>
              <div class="form-group">
                <label>Address</label>
                <input type="text" class="form-control" v-model="selectedUser.profile.address" />
              </div>
              <div class="form-check form-switch mt-3">

                <label class="form-check-label">
                  {{ selectedUser.active ? 'Active' : 'Inactive' }}
                </label>
                <input class="form-check-input" type="checkbox" v-model="selectedUser.active"
                  @click.stop="changeStatus(selectedUser, selectedUser.active)" />
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
              <button type="button" class="btn btn-primary" @click="saveChanges">Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filters: {
        roles: {
          admin: false,
          user: false,
        },
      },
      users: [
        {
          id: 1,
          username: 'john_doe',
          email: 'john@example.com',
          role: 'User',
          createdAt: '2024-01-01T10:00:00Z',
          active: true,
          profile: {
            phoneNumber: '123-456-7890',
            address: '123 Elm Street',
          },
        },
        {
          id: 2,
          username: 'jane_smith',
          email: 'jane@example.com',
          role: 'Admin',
          createdAt: '2024-02-01T10:00:00Z',
          active: false,
          profile: {
            phoneNumber: '987-654-3210',
            address: '456 Oak Avenue',
          },
        },
      ],
      currentPage: 1,
      perPage: 2,
      sortOption: 'username',
      selectedUser: null, // Initially set to null
      showMessage: false,
      validationErrors: [],
      showModal: false, // Control the visibility of the modal
    };
  },
  computed: {
    filteredUsers() {
      const roleFilter = this.filters.roles;
      const sortedUsers = [...this.users].sort((a, b) => {
        if (this.sortOption === 'username') {
          return a.username.localeCompare(b.username);
        } else if (this.sortOption === 'createdAt') {
          return new Date(a.createdAt) - new Date(b.createdAt);
        }
        return 0;
      });

      return sortedUsers.filter((user) => {
        const matchesRole =
          (roleFilter.admin && user.role === 'Admin') ||
          (roleFilter.user && user.role === 'User') ||
          (!roleFilter.admin && !roleFilter.user);
        return matchesRole;
      });
    },
    paginatedUsers() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredUsers.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredUsers.length / this.perPage);
    },
  },
  methods: {
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    selectUser(user) {
      this.selectedUser = { ...user }; // Clone the selected user
      this.showModal = true; // Show modal
    },
    closeModal() {
      this.showModal = false;
      this.selectedUser = null; // Reset the selected user
      this.showMessage = false; // Hide success message
    },
    changeStatus(user, isActive) {
      user.active = !isActive;
    },
    saveChanges() {
      this.validationErrors = [];
      if (!this.selectedUser.username) this.validationErrors.push('Username is required.');
      if (!this.selectedUser.email) this.validationErrors.push('Email is required.');
      if (!this.validationErrors.length) {
        // Simulate saving changes (add your API call here)
        this.showMessage = true;
      }
    },
  },
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
}

.page-title {
  font-size: 1.5rem;
  font-weight: 600;
}

.list-header {
  margin-right: 0;
  margin-left: 0;
  border-bottom: 0;
  border-radius: 5px;
}

.list-group-item {
  cursor: pointer;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1050;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-dialog {
  width: 500px;
}

.modal-content {
  padding: 20px;
}

.btn-close {
  background: none;
  border: none;
  font-size: 1.5rem;
}

.alert {
  margin-bottom: 1rem;
}
</style>
