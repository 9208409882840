<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body pt-3">
          <!-- Bordered Tabs -->
          <ul class="nav nav-tabs nav-tabs-bordered" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#listing-request"
                aria-selected="true" role="tab">Request</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" data-bs-toggle="tab" data-bs-target="#listing-review" aria-selected="false"
                role="tab" tabindex="-1">Review</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" data-bs-toggle="tab" data-bs-target="#manage-tab" role="tab"
                aria-controls="manage-tab" aria-selected="false" tabindex="-1">Manage Requests</button>
            </li>
          </ul>
          <div class="tab-content pt-2">
            <!-- Request Tab -->
            <div class="tab-pane fade listing-request active show" id="listing-request" role="tabpanel">
              <h5 class="card-title">Request Listing</h5>
              <form @submit.prevent="submitRequest">
                <div class="row mb-3">
                  <label for="serviceDescription" class="col-md-4 col-lg-3 col-form-label">Service Description</label>
                  <div class="col-md-8 col-lg-9">
                    <textarea v-model="form.serviceDescription" class="form-control" id="serviceDescription"
                      style="height: 100px"></textarea>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="location" class="col-md-4 col-lg-3 col-form-label">Location</label>
                  <div class="col-md-8 col-lg-9">
                    <input v-model="form.location" type="text" class="form-control" id="location">
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="categories" class="col-md-4 col-lg-3 col-form-label">Categories</label>
                  <div class="col-md-8 col-lg-9">
                    <input v-model="form.categories" type="text" class="form-control" id="categories">
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="uploadMedia" class="col-md-4 col-lg-3 col-form-label">Upload Media</label>
                  <div class="col-md-8 col-lg-9">
                    <input ref="uploadMedia" type="file" class="form-control" id="uploadMedia"
                      @change="handleFileUpload('media', $event)">
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="uploadProposal" class="col-md-4 col-lg-3 col-form-label">Upload Proposal Document</label>
                  <div class="col-md-8 col-lg-9">
                    <input ref="uploadProposal" type="file" class="form-control" id="uploadProposal"
                      @change="handleFileUpload('proposal', $event)">
                  </div>
                </div>
                <div class="text-center">
                  <button type="submit" class="btn btn-primary">Submit</button>
                  <button type="reset" class="btn btn-secondary" @click="resetForm">Reset</button>
                </div>
              </form>
              <div v-if="confirmationVisible" class="alert alert-success mt-3" role="alert">
                Your request has been submitted successfully!
              </div>
            </div>

            <!-- Review Tab -->
            <div class="tab-pane fade pt-3" id="listing-review" role="tabpanel">
              <h5 class="card-title">Review Listings</h5>
              <div class="row mb-3">
                <label for="statusFilter" class="col-md-4 col-lg-3 col-form-label">Filter by Status</label>
                <div class="col-md-8 col-lg-9">
                  <select v-model="statusFilter" class="form-control" id="statusFilter">
                    <option value="approved">Approved</option>
                    <option value="pending">Pending</option>
                    <option value="acknowledged">Acknowledged</option>
                    <option value="rejected">Rejected</option>
                  </select>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Service Request ID</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Status Remarks</th>
                      <th>Pay</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="request in filteredRequests" :key="request.id">
                      <td>{{ request.id }}</td>
                      <td>{{ request.date }}</td>
                      <td>{{ request.status }}</td>
                      <td>{{ request.remarks }}</td>
                      <td>
                        <button class="btn btn-primary" :disabled="request.status !== 'Approved'">Pay</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> <!-- Manage Requests Tab -->
            <div class="tab-pane fade" id="manage-tab" role="tabpanel">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Request ID</th>
                      <th scope="col">Date</th>
                      <th scope="col">Status</th>
                      <th scope="col">Status Remarks</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Date Expiry</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="request in manageRequests" :key="request.id">
                      <td>{{ request.id }}</td>
                      <td>{{ request.date }}</td>
                      <td>
                        <select v-model="request.status" class="form-select">
                          <option value="pending">Pending</option>
                          <option value="acknowledged">Acknowledged</option>
                          <option value="approved">Approved</option>
                          <option value="rejected">Rejected</option>
                        </select>
                      </td>
                      <td>{{ request.remarks }}</td>
                      <td><input v-model.number="request.amount" type="number" class="form-control"></td>
                      <td>{{ request.expiryDate }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div><!-- End Bordered Tabs -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        serviceDescription: '',
        location: '',
        categories: '',
        media: null,
        proposal: null
      },
      statusFilter: 'approved',
      manageRequests: [
        {
          id: '001',
          date: '2024-08-01',
          status: 'pending',
          remarks: 'Pending approval',
          amount: null,
          expiryDate: '2024-08-16'
        }
      ],
      confirmationVisible: false
    };
  },
  computed: {
    filteredRequests() {
      // Example data, replace with actual data fetching logic
      const allRequests = [
        { id: 'REQ12345', date: '2023-08-01', status: 'Approved', remarks: 'Ready for payment' },
        { id: 'REQ12346', date: '2023-08-02', status: 'Pending', remarks: 'Awaiting approval' }
      ];
      return allRequests.filter(request => request.status.toLowerCase() === this.statusFilter);
    }
  },
  methods: {
    submitRequest() {
      // Handle form submission logic here
      this.confirmationVisible = true;
    },
    resetForm() {
      this.form = {
        serviceDescription: '',
        location: '',
        categories: '',
        media: null,
        proposal: null
      };
    },
    handleFileUpload(type, event) {
      this.form[type] = event.target.files[0];
    }
  }
};
</script>

<style scoped>
/* Add any additional styling here if needed */
</style>