export const HelpdeskRequests = [
  { id: 4, uid: 'REQ126', submissionDate: '2024-08-10', status: 'pending' },
  { id: 5, uid: 'REQ127', submissionDate: '2024-07-20', status: 'closed' },
  { id: 6, uid: 'REQ128', submissionDate: '2024-08-03', status: 'expired' },
  { id: 7, uid: 'REQ129', submissionDate: '2024-08-12', status: 'pending' },
  { id: 8, uid: 'REQ130', submissionDate: '2024-06-30', status: 'closed' },
  { id: 9, uid: 'REQ131', submissionDate: '2024-08-15', status: 'pending' },
  { id: 10, uid: 'REQ132', submissionDate: '2024-07-25', status: 'expired' },
  { id: 11, uid: 'REQ133', submissionDate: '2024-08-20', status: 'pending' },
  { id: 12, uid: 'REQ134', submissionDate: '2024-08-22', status: 'closed' },
  { id: 13, uid: 'REQ135', submissionDate: '2024-07-05', status: 'expired' }
];

export const requestHistories = [
  {
    id: 1, history: [
      { date: '2024-08-01', description: 'Request submitted.' },
      { date: '2024-08-02', description: 'Request reviewed.' }
    ]
  },
  {
    id: 2, history: [
      { date: '2024-08-05', description: 'Request submitted.' },
      { date: '2024-08-06', description: 'Request closed.' }
    ]
  },
  {
    id: 3, history: [
      { date: '2024-07-15', description: 'Request submitted.' },
      { date: '2024-07-16', description: 'Request expired.' }
    ]
  },
  {
    id: 4, history: [
      { date: '2024-08-10', description: 'Request submitted.' },
      { date: '2024-08-11', description: 'Request under review.' }
    ]
  },
  {
    id: 5, history: [
      { date: '2024-07-20', description: 'Request submitted.' },
      { date: '2024-07-21', description: 'Request closed.' }
    ]
  },
  {
    id: 6, history: [
      { date: '2024-08-03', description: 'Request submitted.' },
      { date: '2024-08-04', description: 'Request expired.' }
    ]
  },
  {
    id: 7, history: [
      { date: '2024-08-12', description: 'Request submitted.' },
      { date: '2024-08-13', description: 'Request under review.' }
    ]
  },
  {
    id: 8, history: [
      { date: '2024-06-30', description: 'Request submitted.' },
      { date: '2024-07-01', description: 'Request closed.' }
    ]
  },
  {
    id: 9, history: [
      { date: '2024-08-15', description: 'Request submitted.' },
      { date: '2024-08-16', description: 'Request under review.' }
    ]
  },
  {
    id: 10, history: [
      { date: '2024-07-25', description: 'Request submitted.' },
      { date: '2024-07-26', description: 'Request expired.' }
    ]
  },
  {
    id: 11, history: [
      { date: '2024-08-20', description: 'Request submitted.' },
      { date: '2024-08-21', description: 'Request under review.' }
    ]
  },
  {
    id: 12, history: [
      { date: '2024-08-22', description: 'Request submitted.' },
      { date: '2024-08-23', description: 'Request closed.' }
    ]
  },
  {
    id: 13, history: [
      { date: '2024-07-05', description: 'Request submitted.' },
      { date: '2024-07-06', description: 'Request expired.' }
    ]
  }
];


const combinedRequests = HelpdeskRequests.map(request => {
  const history = requestHistories.find(history => history.id === request.id);
  return { ...request, history: history ? history.history : [] };
});

export const HelpdeskRequestsWithHistory = combinedRequests;