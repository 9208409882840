<template>
  <div class="row">

    <!-- Filter Column -->
    <div class="col-lg-3 col-md-3">
      <!-- Filters for Desktop (Visible on md and larger screens) -->
      <div class="d-none d-md-block mt-3">
        <div class="my-3">
          <input class="form-control" placeholder="Search" type="text" v-model="searchInput">
        </div>
        <!-- Sorting Options -->
        <div class="col-md-12 col-lg-12">
          <label class="col-form-label">Sort Results By:</label>
          <select class="form-control col-form-select mx-2 d-inline" v-model="sortOption">
            <option value="rating-desc">Rating: High-To-Low</option>
            <option value="tat-asc">TAT: Low-To-High</option>
          </select>
        </div>
        <h5 class="mt-5">Filters</h5>
        <!-- Recent Filters -->
        <div>
          <ul class="list-group">
            <li class="list-group-item" style="border-style: none;">
              <input class="form-check-input me-1" type="checkbox" value="recent" v-model="selectedFilters"> Recent
            </li>
          </ul>
        </div>
        <!-- Location Filter -->
        <div class="my-3">
          <div class="form-group position-relative">
            <input type="text" class="form-control" placeholder="Location" v-model="currentLocationFilter"
              @keydown.enter="applyLocationFilter" @input="handleLocationInput" list="list-timezone">
            <button v-if="currentLocationFilter" class="btn btn-clear" @click="clearLocation"
              style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%);">
              &times;
            </button>
            <datalist id="list-timezone">
              <option v-for="loc in locations" :key="loc">{{ loc }}</option>
            </datalist>
          </div>
        </div>

        <!-- Category Filter -->
        <div class="my-3">
          <div class="form-group position-relative">
            <input type="text" class="form-control" placeholder="Category" v-model="currentCategoryFilter"
              @keydown.enter="applyCategoryFilter" @input="handleCategoryInput" list="list-category">
            <button v-if="currentCategoryFilter" class="btn btn-clear" @click="clearCategory"
              style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%);">
              &times;
            </button>
            <datalist id="list-category">
              <option v-for="cat in categories" :key="cat">{{ cat }}</option>
            </datalist>
          </div>
        </div>


        <!-- Applied Filters  -->
        <div class="row mt-5">
          <div class="pagetitle">
            <div>
              <div class="mb-12">
                <div v-if="isFilterApplied()" class="col-lg-12 col-md-12 ">
                  <div class="mb-3">
                    <h5 class="font-size-16 font-weight-900 d-inline">Applied Filters</h5>
                    <button class="btn btn-danger btn-sm ms-2" @click="removeAllFilters">
                      &times;
                    </button>
                  </div>
                  <span v-for="(filter, index) in appliedFilters" :key="index" class="badge me-1" :class="filter.class"
                    @click="removeFilter(filter)" style="cursor: pointer;">
                    x &nbsp; {{ filter.label }}
                  </span>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Filters for Mobile (Collapsible) -->
      <div class="d-md-none">
        <button class="btn btn-primary w-100 mb-2" type="button" data-bs-toggle="collapse"
          data-bs-target="#mobileFilters" aria-expanded="false" aria-controls="mobileFilters">
          Filters
        </button>
        <div class="collapse" id="mobileFilters">
          <!-- Recent Filters -->
          <div>
            <ul class="list-group mt-2">
              <li class="list-group-item" style="border-style: none;">
                <input class="form-check-input me-1" type="checkbox" value="recent" v-model="selectedFilters"> Recent
              </li>
              <!-- Additional filters can be added here -->
            </ul>
          </div>
          <!-- Location Filter -->
          <div class="my-3">
            <div class="form-group position-relative">
              <input type="text" class="form-control" placeholder="Location" v-model="currentLocationFilter"
                @keydown.enter="applyLocationFilter" @input="handleLocationInput" list="list-timezone">
              <button v-if="currentLocationFilter" class="btn btn-clear" @click="clearLocation"
                style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%);">
                &times;
              </button>
              <datalist id="list-timezone">
                <option v-for="loc in locations" :key="loc">{{ loc }}</option>
              </datalist>
            </div>
          </div>

          <!-- Category Filter -->
          <div class="my-3">
            <div class="form-group position-relative">
              <input type="text" class="form-control" placeholder="Category" v-model="currentCategoryFilter"
                @keydown.enter="applyCategoryFilter" @input="handleCategoryInput" list="list-category">
              <button v-if="currentCategoryFilter" class="btn btn-clear" @click="clearCategory"
                style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%);">
                &times;
              </button>
              <datalist id="list-category">
                <option v-for="cat in categories" :key="cat">{{ cat }}</option>
              </datalist>
            </div>
          </div>

          <!-- Applied Filters and Sorting Options -->
          <div class="row mt-2">
            <div class="pagetitle">
              <div>
                <div class="mb-12">
                  <div v-if="isFilterApplied()" class="col-lg-12 col-md-12">
                    <div>
                      <h5 class="font-size-16 font-weight-900 d-inline">Applied Filters:</h5>
                      <button v-if="isFilterApplied()" class="btn btn-danger btn-sm ms-2 d-inline"
                        @click="removeAllFilters">
                        &times;
                      </button>
                    </div>
                    <div class="mt-3">
                      <span v-for="(filter, index) in appliedFilters" :key="index" class="badge me-1 py-2"
                        :class="filter.class" @click="removeFilter(filter)" style="cursor: pointer;">
                        x &nbsp; {{ filter.label }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
        <!-- Search Input -->
        <div class="mt-3">
          <input class="form-control" placeholder="Search" type="text" v-model="searchInput">
        </div>
        <div class="row col-md-12 col-lg-12 mt-4">
          <div class="col-auto">
            <label class="col-form-label">Sort Results By:</label>
          </div>
          <div class="col-auto">
            <select class="form-control col-form-select" v-model="sortOption">
              <option value="rating-desc">Rating: High-To-Low</option>
              <option value="tat-asc">TAT: Low-To-High</option>
            </select>
          </div>
        </div>
      </div>

    </div>


    <!-- Main Content Column -->
    <div class="col-lg-9 col-md-9 main-content">
      <!-- Listing Section -->
      <div v-if="serviceProviders && paginatedProviders.length > 0">
        <div class="row scrollable-container"
          style="background-color: transparent; max-height: 520px; overflow-y: scroll;">
          <div class="card-body" style="padding: 20px 10px;">
            <div v-for="(provider, index) in paginatedProviders" :key="index" class="card mb-3 rounded-5">
              <div class=" card-header border-0 rounded-bottom-5 mx-3" style="padding-bottom: 0;">
                <div class="row">
                  <h4 class="col-8">{{ provider.name }}</h4>
                  <div class="col-4 text-end">
                    <!-- Phone Link and Social Icons -->
                    <a :href="'tel:' + provider.phone" class="icon"><i class="bi bi-telephone"></i></a>
                    <a :href="'https://wa.me/' + provider.whatsapp" target="_blank" class="icon"><i
                        class="bi bi-whatsapp"></i></a>
                    <a :href="'https://facebook.com/' + provider.facebook" target="_blank" class="icon"><i
                        class="bi bi-facebook"></i></a>
                  </div>
                </div>
              </div>
              <div class="row card-body">
                <div class="col-lg-3 col-md-3">
                  <!-- Carousel for images -->
                  <div :id="'carouselExampleIndicators' + index" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                      <div v-for="(image, imgIndex) in provider.images" :key="imgIndex" class="carousel-item"
                        :class="{ active: imgIndex === 0 }">
                        <img :src="image" class="d-block w-100" alt="...">
                      </div>
                    </div>
                    <button class="carousel-control-prev" type="button"
                      :data-bs-target="'#carouselExampleIndicators' + index" data-bs-slide="prev">
                      <span class="carousel-control-prev-icon"></span>
                    </button>
                    <button class="carousel-control-next" type="button"
                      :data-bs-target="'#carouselExampleIndicators' + index" data-bs-slide="next">
                      <span class="carousel-control-next-icon"></span>
                    </button>
                  </div>
                </div>
                <div class="col-lg-9 col-md-9">
                  <div class="row mb-1">
                    <div class="col-4">Location:</div>
                    <div class="col-8">{{ provider.location }}</div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-4">Address:</div>
                    <div class="col-8">{{ provider.address }}</div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-4">Speciality:</div>
                    <div class="col-8">{{ provider.speciality }}</div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-4">Review:</div>
                    <div class="col-8">{{ provider.review }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">TAT:</div>
                    <div class="col-8">{{ provider.tat }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">Added Date:</div>
                    <div class="col-8">{{ provider.addedDate }}</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <!-- Pagination controls -->
        <nav aria-label="Page navigation" class="pagination-container">
          <ul class="pagination justify-content-center mt-2">
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
              <button class="page-link" @click="changePage(currentPage - 1)">Previous</button>
            </li>
            <li v-for="page in totalPages" :key="page" class="page-item" :class="{ active: page === currentPage }">
              <button class="page-link" @click="changePage(page)">{{ page }}</button>
            </li>
            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
              <button class="page-link" @click="changePage(currentPage + 1)">Next</button>
            </li>
          </ul>
        </nav>
      </div>

      <div v-else class="d-flex justify-content-center align-items-center" style="height: 100%;">
        <h5>(No Providers found)</h5>
      </div>

    </div>

  </div>
</template>

<script>
import { Providers } from '@/data/providers';
export default {
  data() {
    return {

      /* dynamic data fields */
      serviceProviders: Providers,
      locations: [
        'Lucknow',
        'Delhi',
        'Mumbai',
        'Bangalore',
        'Kolkata',
        'Chennai',
        'Hyderabad',
        'Pune',
        'Ahmedabad',
        'Jaipur',
        'Surat',
        'Noida',
        'Gurgaon',
        'Chandigarh',
        'Nagpur',
        'Indore',
        'Patna',
        'Bhopal',
        'Visakhapatnam',
        'Vadodara'
      ],
      categories: [
        'Medical',
        'Plumbing',
        'Electrical',
        'Cleaning',
        'Carpentry',
        'Painting',
        'HVAC',
        'Pest Control',
        'Appliance Repair',
        'Roofing',
        'Security Systems',
        'Beauty & Wellness',
        'Restaurants',
        'Landscaping',
        'Pet Care',
        'Fitness',
        'Legal',
        'Accounting',
        'Event Planning',
        'Tutoring'
      ],
      /**************** */

      searchInput: '',
      selectedLocations: [],
      selectedCategories: [],
      selectedFilters: [],
      currentLocationFilter: '',
      currentCategoryFilter: '',
      sortOption: 'rating-desc',
      currentPage: 1,
      pageSize: 5,
    };
  },
  computed: {
    filteredProviders() {
      return this.serviceProviders
        .filter(provider =>
          // Search filter: Check if the provider's name or speciality includes the search term
          (this.searchInput === '' || provider.name.toLowerCase().includes(this.searchInput.toLowerCase()) || provider.speciality.toLowerCase().includes(this.searchInput.toLowerCase())) &&

          // Location filter: Check if the provider's location is in the selectedLocations array
          (this.selectedLocations.length === 0 || this.selectedLocations.includes(provider.location)) &&

          // Category filter: Check if the provider's category is in the selectedCategories array
          (this.selectedCategories.length === 0 || this.selectedCategories.includes(provider.category)) &&

          // Additional filters: Check other custom filters (like 'recent', 'favorites', 'contacted')
          (this.selectedFilters.length === 0 || this.selectedFilters.some(filter => {
            if (filter === 'recent') {
              const recentDateThreshold = new Date(); // Set your threshold date for "recent"
              recentDateThreshold.setDate(recentDateThreshold.getDate() - 30); // Example: last 30 days
              return new Date(provider.addedDate) >= recentDateThreshold; // Check if addedDate is within threshold
            }
            // Add more logic for other filters like 'favorites' or 'contacted' here
            return false;
          }))
        )
        .sort((a, b) => {
          // Sort logic based on selected sorting options
          if (this.sortOption === 'rating-desc') {
            return parseFloat(b.review) - parseFloat(a.review); // Sort by rating, descending
          } else if (this.sortOption === 'tat-asc') {
            return parseFloat(a.tat) - parseFloat(b.tat); // Sort by TAT (Turnaround time), ascending
          } else if (this.selectedFilters.includes('recent')) {
            return new Date(b.addedDate) - new Date(a.addedDate); // Sort by most recent
          }
          return 0; // Default sort order
        });
    },
    paginatedProviders() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredProviders.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredProviders.length / this.pageSize);
    },
    appliedFilters() {
      const filters = [];

      // Add all selected location filters
      if (this.selectedLocations.length > 0) {
        this.selectedLocations.forEach(location => {
          filters.push({ label: location, class: 'bg-secondary' });
        });
      }

      // Add all selected category filters
      if (this.selectedCategories.length > 0) {
        this.selectedCategories.forEach(category => {
          filters.push({ label: category, class: 'bg-success' });
        });
      }

      // Check if the 'recent' filter is applied
      if (this.selectedFilters.includes('recent')) {
        filters.push({ label: 'Recent', class: 'bg-primary' });
      }

      // Add more filters if needed
      return filters;
    }

  },
  methods: {
    isFilterApplied() {
      if (this.selectedCategories.length > 0 ||
        this.selectedLocations.length > 0 ||
        this.selectedFilters.length > 0) {
        return true;
      }
      else {
        return false;
      }
    },
    handleLocationInput(event) {
      const selectedValue = event.target.value;
      const matchedLocation = this.locations.find(location =>
        location.toLowerCase() === selectedValue.toLowerCase()
      );

      if (matchedLocation) {
        if (!this.selectedLocations.includes(matchedLocation)) {
          this.selectedLocations.push(matchedLocation);
        }
        this.currentLocationFilter = '';
      }
    },

    applyLocationFilter() {
      if (this.currentLocationFilter) {
        const matchedLocation = this.locations.find(location =>
          location.toLowerCase() === this.currentLocationFilter.toLowerCase()
        );

        if (matchedLocation) {
          if (!this.selectedLocations.includes(matchedLocation)) {
            this.selectedLocations.push(matchedLocation);
          }
        } else {
          // If no exact match, add the entered value as-is
          if (!this.selectedLocations.includes(this.currentLocationFilter)) {
            this.selectedLocations.push(this.currentLocationFilter);
          }
        }
        this.currentLocationFilter = '';
      }
    },
    applyCategoryFilter() {
      if (this.currentCategoryFilter && !this.selectedCategories.includes(this.currentCategoryFilter)) {
        this.selectedCategories.push(this.currentCategoryFilter);
        this.currentCategoryFilter = '';
      }
    },
    handleLocationInput(event) {
      const selectedValue = event.target.value;
      if (this.locations.includes(selectedValue)) {
        if (!this.selectedLocations.includes(selectedValue)) {
          this.selectedLocations.push(selectedValue);
        }
        this.currentLocationFilter = '';
      }
    },
    handleCategoryInput(event) {
      const selectedValue = event.target.value;
      if (this.categories.includes(selectedValue)) {
        if (!this.selectedCategories.includes(selectedValue)) {
          this.selectedCategories.push(selectedValue);
        }
        this.currentCategoryFilter = '';
      }
    },
    clearLocation() {
      this.currentLocationFilter = '';
      this.selectedLocations = [];
    },
    clearCategory() {
      this.currentCategoryFilter = '';
      this.selectedCategories = [];
    },
    removeFilter(filter) {
      // Check if the filter is in the selectedLocations array and remove it
      if (this.selectedLocations.includes(filter.label)) {
        this.selectedLocations = this.selectedLocations.filter(location => location !== filter.label);
      }
      // Check if the filter is in the selectedCategories array and remove it
      else if (this.selectedCategories.includes(filter.label)) {
        this.selectedCategories = this.selectedCategories.filter(category => category !== filter.label);
      }
      // Remove from the selectedFilters array if it's a generic filter like 'recent'
      else {
        this.selectedFilters = this.selectedFilters.filter(f => f !== filter.label.toLowerCase());
      }
    },
    removeAllFilters() {
      // Clear all selected locations, categories, and filters
      this.selectedLocations = [];
      this.selectedCategories = [];
      this.selectedFilters = [];
    },

    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },


    // Fetch providers on page load
    fetchProviders() {
      // Implement API call if needed
    }
  },
  mounted() {
    // Uncomment this if you want to fetch providers on mount
    // this.fetchProviders();

    // URL Variables (Location and Category) on Page Load
    const urlParams = new URLSearchParams(window.location.search);
    this.selectedLocations = this.selectedLocations.length ? this.selectedLocations : (urlParams.get('location') ? [urlParams.get('location')] : []);
    this.selectedCategories = this.selectedCategories.length ? this.selectedCategories : (urlParams.get('category') ? [urlParams.get('category')] : []);
    this.searchInput = urlParams.get('searchterm') || '';
  }
};
</script>

<style scoped>
.main-content {
  margin-top: -20px;
}

.icon {
  margin-right: 10px;
  color: #000;
}

.card {
  border: none;
}

.carousel-inner img {
  height: 120px;
  object-fit: cover;
}

.pagination-container {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .main-content {
    margin-top: 0px;
  }

  /* Adjustments for mobile view */
  .col-lg-3,
  .col-md-3 {
    width: 100%;
  }

  .col-lg-9,
  .col-md-9 {
    width: 100%;
  }

  .pagination-container {
    margin-bottom: 20px;
  }

  /* Limit the visible area to one card at a time */
  .scrollable-container {
    max-height: 380px !important;
    overflow-y: scroll;
  }

  .card {
    margin-bottom: 20px;
  }

}
</style>
