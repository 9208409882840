<template>
    <div class="promotional-content">
      <h4 class="section-title">Promotional Content</h4>
      
      <!-- Loading Spinner -->
      <div v-if="loading" class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
  
      <!-- Error Message -->
      <div v-if="error" class="alert alert-danger" role="alert">
        {{ error }}
      </div>
  
      <!-- Promotional Content -->
      <div v-if="!loading && !error">
        <div v-if="content" class="content">
          <h5>{{ content.title }}</h5>
          <p>{{ content.description }}</p>
          <img :src="content.image" alt="Promotional" class="img-fluid" />
        </div>
        <div v-else class="static-content">
          <h5>Static Content</h5>
          <p>This is static content displayed when no dynamic content is available.</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        content: null,
        loading: true,
        error: null
      };
    },
    created() {
      this.loadContent();
    },
    methods: {
      async loadContent() {
        try {
          // Example API call (Replace with actual API endpoint)
          const response = await fetch('https://api.example.com/promotional-content');
          
          if (!response.ok) throw new Error('Failed to load content.');
  
          const data = await response.json();
          this.content = data; // Set the content from the API
        } catch (err) {
          this.error = err.message; // Display error if API call fails
        } finally {
          this.loading = false; // Stop the loading spinner
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .promotional-content {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .section-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  
  .content {
    text-align: center;
  }
  
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  
  .static-content {
    text-align: center;
    padding: 20px;
    border: 1px dashed #ddd;
    background-color: #fff;
  }
  
  .spinner-border {
    display: block;
    margin: 20px auto;
  }
  </style>
  