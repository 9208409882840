<template>
  <div class="container">
    <!-- Tabs -->
    <ul class="nav nav-tabs" id="adminHelpDeskTabs" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link active" id="review-tab" data-bs-toggle="tab" href="#review" role="tab" aria-controls="review"
          aria-selected="true">Review</a>
      </li>
    </ul>

    <!-- Tab Content -->
    <div class="tab-content" id="adminHelpDeskTabsContent">
      <!-- Review Tab -->
      <div class="tab-pane fade show active" id="review" role="tabpanel" aria-labelledby="review-tab">
        <div class="card mb-4">
          <div class="card-body">
            <h5 class="card-title">Review Help Requests</h5>
            <div class="mb-3">
              <label for="statusFilter" class="form-label">Filter by Status</label>
              <select v-model="statusFilter" @change="filterRequests" class="form-select" id="statusFilter">
                <option value="all">All</option>
                <option value="pending">Pending</option>
                <option value="closed">Closed</option>
                <option value="expired">Expired</option>
              </select>
            </div>
            <div class="table-responsive">
              <table class="table table-striped table-bordered">
                <thead class="sticky-top" style="border: 2px solid #dee2e6;">
                  <tr>
                    <th>Request UID</th>
                    <th>Submission Date</th>
                    <th>Status</th>
                    <th>Expiration</th>
                    <th>Actionables</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="request in helpdeskRequestsWithHistory" :key="request.id">
                    <td>{{ request.uid }}</td>
                    <td>{{ formatDate(request.submissionDate) }}</td>
                    <td>{{ request.status }}</td>
                    <td>{{ formatDate(calculateExpiration(request.submissionDate)) }}</td>
                    <td>
                      <div class="dropdown">
                        <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" id="actionDropdown"
                          data-bs-toggle="dropdown" aria-expanded="false">
                          Select Action
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="actionDropdown">
                          <li><a class="dropdown-item" href="#" @click.prevent="showTraceHistory(request)">Trace
                              History</a></li>
                          <li><a class="dropdown-item" href="#" @click.prevent="showReplyModal(request)">Reply</a></li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Trace History Modal -->
    <div class="modal fade" id="traceHistoryModal" tabindex="-1" ref="traceHistoryModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Trace History</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <ul class="list-group">
              <li v-for="(event, index) in showHistory" :key="index" class="list-group-item">
                <strong>{{ formatDate(event.date) }}:</strong> {{ event.description }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Reply Modal -->
    <div class="modal fade" id="replyModal" tabindex="-1" ref="replyModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Reply to Request</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="replyMessage" class="form-label">Your Message</label>
              <textarea v-model="replyMessage" class="form-control" id="replyMessage" rows="3"></textarea>
              <div v-if="errorMessage" class="text-danger mt-2">{{ errorMessage }}</div>
            </div>
            <button @click="sendReply" class="btn btn-primary">Send Reply</button>

            <!-- Success Message -->
            <div v-if="successMessage" class="alert alert-success mt-3">
              {{ successMessage }}
            </div>
          </div>
        </div>
      </div>
    </div>



  </div>
</template>

<script>
import { Modal } from 'bootstrap';
import { HelpdeskRequestsWithHistory } from '../../data/helpdesk';
import { formatDate } from '../../utils/date';

export default {
  data() {
    return {
      statusFilter: 'all',
      helpdeskRequestsWithHistory: HelpdeskRequestsWithHistory,
      showHistory: null,
      replyMessage: '',
      successMessage: '',
      errorMessage: '',
      formatDate
    };
  },
  methods: {
    calculateExpiration(submissionDate) {
      const date = new Date(submissionDate);
      date.setDate(date.getDate() + 30);
      return formatDate(date);
    },
    showTraceHistory(request) {
      this.showHistory = request.history;
      const modalElement = this.$refs.traceHistoryModal;
      const modal = new Modal(modalElement);
      modal.show();
    },
    showReplyModal(request) {
      this.replyMessage = ''; // Reset reply message
      const modalElement = this.$refs.replyModal;
      const modal = new Modal(modalElement);
      modal.show();
    },
    sendReply() {
      console.log('Reply sent:', this.replyMessage);
      if (!this.replyMessage.trim()) {
        this.errorMessage = 'Reply message cannot be empty.';
        return;
      } else {
        this.errorMessage = ''; // Clear error message if valid
      }

      // Logic to send the reply goes here
      // For example:
      // this.api.sendReply(this.replyMessage);

      // Display success message
      this.successMessage = 'Your reply has been sent successfully!';

      // Clear the message after a timeout (optional)
      setTimeout(() => {
        this.successMessage = '';
        this.replyMessage = ''; // Clear the reply message

        // Close the modal properly
        /*const modal = new Modal(this.$refs.replyModal);
        modal.hide();*/
      }, 2000); // Message will disappear after 2 seconds
    },
    filterRequests() {
      this.helpdeskRequestsWithHistory.filter(request =>
        this.statusFilter === 'all' || request.status === this.statusFilter
      );
    }
  },
  mounted() {
    this.filterRequests(); // Initialize with all requests
  }
};
</script>


<style scoped>
/* Your CSS styles here */
</style>