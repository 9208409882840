<template>
    <div class="container">
      <!-- Contact Us Form -->
      <div class="card mb-4">
        <div class="card-body">
          <h5 class="card-title">Contact Us</h5>
          <form @submit.prevent="submitForm">
            <div class="mb-3">
              <label for="emailOrMobile" class="form-label">Email/Mobile</label>
              <input
                v-model="contactForm.emailOrMobile"
                type="text"
                class="form-control"
                id="emailOrMobile"
                required
              />
            </div>
            <div class="mb-3">
              <label for="description" class="form-label">Request Description</label>
              <textarea
                v-model="contactForm.description"
                class="form-control"
                id="description"
                rows="3"
                required
              ></textarea>
            </div>
            <div class="mb-3">
              <label for="media" class="form-label">Media Upload (Optional)</label>
              <input
                type="file"
                @change="handleFileUpload"
                class="form-control"
                id="media"
                accept=".png, .jpg, .jpeg, .gif, .pdf"
              />
            </div>
            <button type="submit" class="btn btn-primary me-2">Submit</button>
            <button type="button" @click="resetForm" class="btn btn-secondary">Reset</button>
          </form>
          <div v-if="message" class="mt-3 alert" :class="{'alert-success': success, 'alert-danger': !success}">
            {{ message }}
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  
  export default {
    name: 'ContactUs',
    setup() {
      const contactForm = ref({
        emailOrMobile: '',
        description: '',
        media: null,
      });
      const message = ref('');
      const success = ref(true);
  
      const handleFileUpload = (event) => {
        contactForm.value.media = event.target.files[0];
      };
  
      const submitForm = async () => {
        // Validation can be added here if needed
        try {
          // Mock server request
          // Replace with actual server request code
          console.log('Form submitted:', contactForm.value);
          message.value = 'Your request has been submitted successfully.';
          success.value = true;
          resetForm();
        } catch (error) {
          message.value = 'There was an error submitting your request.';
          success.value = false;
        }
      };
  
      const resetForm = () => {
        contactForm.value = {
          emailOrMobile: '',
          description: '',
          media: null,
        };
        message.value = '';
      };
  
      return {
        contactForm,
        handleFileUpload,
        submitForm,
        resetForm,
        message,
        success,
      };
    },
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 600px;
    margin: auto;
  }
  </style>  