import { createRouter, createWebHistory } from 'vue-router';
import MainLayout from '@/layouts/MainLayout.vue';
import store from '@/store';

// Import views as before
import Home from '@/views/Home.vue';
import Search from '@/views/Search.vue';
import Listings from '@/views/Listing.vue';
import Login from '@/views/Login.vue';
import Register from '@/views/Register.vue';
import Helpdesk from '@/views/Helpdesk.vue';
import Contact from '@/views/Contact.vue';

// Admin Pages
import ManageHelpDesk from '@/views/admin/ManageHelpDesk.vue';
import ManageAds from '@/views/admin/ManageAds.vue';
import ManageSearch from '@/views/admin/ManageSearch.vue';
import ManageUser from '@/views/admin/ManageUser.vue';

//User Pages
import Profile from '@/views/user/Profile.vue';
import LoggedInPromo from '@/views/user/LoggedInPromo.vue';
import LoggedInSearch from '@/views/user/LoggedInSearch.vue';
import LoggedInAdListing from '@/views/user/LoggedInAdListing.vue';
import LoggedInUserHelpDesk from '@/views/user/LoggedInHelpDesk.vue';


const routes = [
  {
    path: '/',
    component: MainLayout,
    children: [
      { path: '/', name: 'Home', component: Home, meta: { breadcrumb: 'Home' }},
      { path: 'search', name: 'Search', component: Search, meta: { breadcrumb: 'Search' } },
      { path: 'contact', name: 'Contact', component: Contact, meta: { breadcrumb: 'Contact' } },
      { path: 'listings', name: 'Listings', component: Listings, meta: { breadcrumb: 'Listings', requiresAuth: true } },
      { path: 'helpdesk', name: 'Helpdesk', component: Helpdesk, meta: { breadcrumb: 'Helpdesk', requiresAuth: true } },

      // Logged in User Pages
      { path: 'service-ad-list', name: 'ServiceAdListing', component: LoggedInAdListing, meta: { breadcrumb: 'Service/Ad Listing', requiresAuth: true, requiresUser: true } },
      { path: 'profile', name: 'Profile', component: Profile, meta: { breadcrumb: 'Profile', requiresAuth: true, requiresUser: true } },
      { path: 'user-help', name: 'UserHelpdesk', component: LoggedInUserHelpDesk, meta: { breadcrumb: 'User Helpdesk', requiresAuth: true, requiresUser: true } },
      { path: 'user-promotional', name: 'UserPromotional', component: LoggedInPromo, meta: { breadcrumb: 'User Promotional', requiresAuth: true, requiresUser: true } },
      { path: 'user-search', name: 'UserSearch', component: LoggedInSearch, meta: { breadcrumb: 'User Search', requiresAuth: true, requiresUser: true } },
      
      // Admin Pages
      { path: 'manage-help', name: 'AdminHelpdesk', component: ManageHelpDesk, meta: { breadcrumb: 'Admin Helpdesk', requiresAuth: true, requiresAdmin: true } },
      { path: 'manage-user', name: 'ManageUsers', component: ManageUser, meta: { breadcrumb: 'Manage Users', requiresAuth: true, requiresAdmin: true } },
      { path: 'manage-ads', name: 'ManageAds', component: ManageAds, meta: { breadcrumb: 'Manage Ads', requiresAuth: true, requiresAdmin: true } },
      { path: 'manage-search', name: 'ManageSearch', component: ManageSearch, meta: { breadcrumb: 'Manage Search', requiresAuth: true, requiresAdmin: true } },
    ]
  },
  { path: '/login', name: 'Login', component: Login },
  { path: '/register', name: 'Register', component: Register },
  { path: '/:pathMatch(.*)*', redirect: '/' }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Navigation guard logic
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.isAuthenticated;
  const isAdmin = store.getters.isAdmin;

  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/login');
  } else if (to.meta.requiresAdmin && (!isAuthenticated || !isAdmin)) {
    next('/'); // Redirect to home or another appropriate page
  } else if (to.meta.requiresUser && isAdmin) {
    next('/'); // Redirect admin away from user-specific pages
  } else if (to.meta.requiresUser && !isAuthenticated) {
    next('/login'); // Ensure non-authenticated users are redirected to login
  } else {
    next();
  }
});

export default router;
