<template>
  <aside id="sidebar" class="sidebar">
    <ul class="sidebar-nav" id="sidebar-nav">
      
      <!-- Public Links -->
      <li class="nav-item">
        <router-link class="nav-link" to="/" @click.native="handleLinkClick">
          <i class="bi bi-house"></i>
          <span>Home</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/search" @click.native="handleLinkClick">
          <i class="bi bi-search"></i>
          <span>Search</span>
        </router-link>
      </li>

      <!-- Non-Authenticated Users (Login/Register) -->
      <template v-if="!isAuthenticated">
        <li class="nav-item">
          <router-link class="nav-link" to="/login" @click.native="handleLinkClick">
            <i class="bi bi-box-arrow-in-right"></i>
            <span>Login</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/register" @click.native="handleLinkClick">
            <i class="bi bi-pass"></i>
            <span>Register</span>
          </router-link>
        </li>
      </template>

      <!-- Authenticated Users -->
      <template v-if="isAuthenticated && !isAdmin">
        <li class="nav-item">
          <router-link class="nav-link" to="/profile" @click.native="handleLinkClick">
            <i class="bi bi-person"></i>
            <span>Profile</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/user-help" @click.native="handleLinkClick">
            <i class="bi bi-headset"></i>
            <span>Helpdesk</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/service-ad-list" @click.native="handleLinkClick">
            <i class="bi bi-card-list"></i>
            <span>Service/Ad Listing</span>
          </router-link>
        </li>
      </template>

      <!-- Admin-Only Links -->
      <template v-if="isAdmin">
        <li class="nav-item">
          <router-link class="nav-link" to="/manage-search" @click.native="handleLinkClick">
            <i class="bi bi-clipboard-data"></i>
            <span>Manage Search</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/manage-user" @click.native="handleLinkClick">
            <i class="bi bi-person-vcard"></i>
            <span>Manage Users</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/manage-ads" @click.native="handleLinkClick">
            <i class="bi bi-sliders"></i>
            <span>Manage Ads</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/manage-help" @click.native="handleLinkClick">
            <i class="bi bi-person-lines-fill"></i>
            <span>Manage Helpdesk</span>
          </router-link>
        </li>
      </template>

      <!-- Public Links -->
      <li class="nav-item" v-if="!isAdmin">
        <router-link class="nav-link" to="/contact" @click.native="handleLinkClick">
          <i class="bi bi-telephone"></i>
          <span>Contact</span>
        </router-link>
      </li>
    </ul>
  </aside>
</template>

<script>
import store from '@/store';

export default {
  name: 'Sidebar',
  computed: {
    isAuthenticated() {
      return !!store.state.user; // Check if user is logged in
    },
    isAdmin() {
      return store.state.user?.role === 'admin'; // Check if user is an admin
    }
  },
  methods: {
    toggleSidebar() {
      document.body.classList.toggle("toggle-sidebar");
    },
    handleLinkClick() {
      if (window.innerWidth < 1199) { // Check if on mobile
        this.toggleSidebar(); // Call toggleSidebar when clicking a link on mobile
      }
    }
  }
};
</script>

<style scoped>
/* Scoped styles for the sidebar component */
</style>
