<template>
    <div class="search-page">
      <!-- Filters Section -->
      <div class="filters">
        <h5>Filters</h5>
        <div class="filter-group">
          <label for="location">Location:</label>
          <select id="location" v-model="filters.location">
            <option v-for="loc in locations" :key="loc" :value="loc">{{ loc }}</option>
          </select>
        </div>
        <div class="filter-group">
          <label for="category">Category:</label>
          <select id="category" v-model="filters.category">
            <option v-for="cat in categories" :key="cat" :value="cat">{{ cat }}</option>
          </select>
        </div>
        <div class="filter-group">
          <label for="contact">Contact Filter:</label>
          <input type="text" id="contact" v-model="filters.contact" />
        </div>
        <button @click="applyFilters" class="btn btn-primary">Apply Filters</button>
        <button @click="resetFilters" class="btn btn-secondary">Reset Filters</button>
      </div>
  
      <!-- Filters Display -->
      <div class="filters-display">
        <span v-if="filtersApplied.length">Applied Filters:</span>
        <span v-for="filter in filtersApplied" :key="filter" class="badge bg-info">{{ filter }}</span>
      </div>
  
      <!-- Sorting Section -->
      <div class="sorting">
        <label for="sort">Sort By:</label>
        <select id="sort" v-model="sortOption" @change="sortResults">
          <option value="rating">Rating</option>
          <option value="tat">TAT (Low-To-High)</option>
        </select>
      </div>
  
      <!-- Search Results -->
      <div class="search-results">
        <div v-if="loading" class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div v-if="error" class="alert alert-danger" role="alert">
          {{ error }}
        </div>
        <div v-if="results.length === 0 && !loading">No items found!</div>
        <div v-if="results.length > 0">
          <div v-for="item in sortedResults" :key="item.id" class="card">
            <div class="card-body">
              <h5 class="card-title">{{ item.name }}</h5>
              <p class="card-text">Location: {{ item.location }}</p>
              <p class="card-text">TAT: {{ item.tat }}</p>
              <p class="card-text">Rating: {{ item.rating }}</p>
              <a :href="'mailto:' + item.email" class="btn btn-primary">Email</a>
              <a :href="'https://wa.me/' + item.whatsapp" class="btn btn-success">WhatsApp</a>
              <a :href="item.socialMedia" class="btn btn-info">Social Media</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
import { ref, reactive, computed, onMounted } from 'vue';

export default {
  setup() {
    const filters = reactive({
      location: '',
      category: '',
      contact: ''
    });
    
    const sortOption = ref('tat');
    const results = ref([]);
    const loading = ref(false);
    const error = ref(null);
    const locations = ref(['Location1', 'Location2']); // Example locations
    const categories = ref(['Category1', 'Category2']); // Example categories
    const filtersApplied = ref([]);

    const filteredResults = computed(() => {
      return results.value.filter(item => {
        const matchLocation = !filters.location || item.location === filters.location;
        const matchCategory = !filters.category || item.category === filters.category;
        const matchContact = !filters.contact || item.contact.includes(filters.contact);
        return matchLocation && matchCategory && matchContact;
      });
    });

    const sortedResults = computed(() => {
      return [...filteredResults.value].sort((a, b) => {
        if (sortOption.value === 'rating') {
          return b.rating - a.rating;
        } else {
          return a.tat - b.tat;
        }
      });
    });

    const applyFilters = async () => {
      loading.value = true;
      try {
        // Example API call (Replace with actual API endpoint)
        const response = await fetch('https://api.example.com/search', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(filters)
        });
  
        if (!response.ok) throw new Error('Failed to fetch results.');
  
        results.value = await response.json();
        filtersApplied.value = Object.entries(filters)
          .filter(([key, value]) => value)
          .map(([key, value]) => `${key}: ${value}`);
      } catch (err) {
        error.value = err.message;
      } finally {
        loading.value = false;
      }
    };

    const resetFilters = () => {
      filters.location = '';
      filters.category = '';
      filters.contact = '';
      filtersApplied.value = [];
      results.value = [];
      applyFilters();
    };

    onMounted(() => {
      applyFilters(); // Initial fetch with default filters
    });

    return {
      filters,
      sortOption,
      results,
      loading,
      error,
      locations,
      categories,
      filtersApplied,
      filteredResults,
      sortedResults,
      applyFilters,
      resetFilters
    };
  }
};
</script>

  
  <style scoped>
  .search-page {
    padding: 20px;
  }
  
  .filters, .sorting {
    margin-bottom: 20px;
  }
  
  .filter-group {
    margin-bottom: 10px;
  }
  
  .filters-display {
    margin-bottom: 20px;
  }
  
  .badge {
    margin-right: 5px;
  }
  
  .search-results .card {
    margin-bottom: 15px;
  }
  
  .spinner-border {
    display: block;
    margin: 20px auto;
  }
  </style>
  