<template>
  <transition name="fade" mode="out-in">
    <main>
      <div class="container">
        <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div class="d-flex justify-content-center py-4">
                  <a href="/" class="logo d-flex align-items-center w-auto">
                    <img src="../assets/logo.png" alt="" style="max-height: 100px; max-width: 200px;" />
                    <!--span class="d-none d-lg-block">Avail India</span-->
                  </a>
                </div>
                <!-- End Logo -->

                <div class="card mb-3">
                  <div class="card-body">
                    <div class="pt-4 pb-2">
                      <h5 class="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                      <p class="text-center small">Enter your username &amp; password to login</p>
                    </div>

                    <form @submit.prevent="handleSubmit" class="row g-3 needs-validation" novalidate>
                      <div class="col-12">
                        <label for="username" class="form-label">Username</label>
                        <div class="input-group has-validation">
                          <span class="input-group-text" id="inputGroupPrepend">@</span>
                          <input type="text" v-model="username" class="form-control" id="username" required />
                          <div class="invalid-feedback">Please enter your username.</div>
                        </div>
                      </div>

                      <div class="col-12">
                        <label for="password" class="form-label">Password</label>
                        <input type="password" v-model="password" class="form-control" id="password" required />
                        <div class="invalid-feedback">Please enter your password!</div>
                      </div>

                      <div class="col-12">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" v-model="remember" id="rememberMe" />
                          <label class="form-check-label" for="rememberMe">Remember me</label>
                        </div>
                      </div>

                      <div class="col-12">
                        <button class="btn btn-primary w-100" type="submit">Login</button>
                      </div>

                      <div class="col-12">
                        <p class="small mb-0">
                          Don't have an account?
                          <router-link to="/register">Create an account</router-link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>

                <div class="credits">
                  Designed by <a href="#">DesignCompanyName</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  </transition>
</template>

<script>
import router from '@/router';
import store from '@/store';

export default {
  data() {
    return {
      username: '',
      password: '',
      remember: false,
    };
  },
  methods: {
    handleSubmit() {
      // Dispatch the login action to Vuex store
      store
        .dispatch('login', {
          username: this.username,
          password: this.password,
        })
        .then(() => {
          if (store.getters.isAdmin)
            router.push('/manage-search');
          else
            router.push('/');
        })
        .catch((error) => {
          console.error('Login failed:', error);
          alert(error);
          // You can handle the error, e.g., show a notification
        });
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
  {
  opacity: 0;
}
</style>
