<template>

    <section class="section profile">
        <div class="row">
            <div class="col-xl-4">
                <div class="card">
                    <div class="card-body profile-card pt-4 d-flex flex-column align-items-center">
                        <img src="https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1722643200&semt=ais_hybrid"
                            alt="Profile" class="rounded-circle" />
                        <h2>{{ profile.fullName }}</h2>
                        <!--h3>Customer</h3-->
                        <div class="social-links mt-2">
                            <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
                            <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
                            <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
                            <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-8">
                <div class="card">
                    <div class="card-body pt-3">
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                            <a class="nav-item nav-link" :class="{ active: activeTab === 'overview' }"
                                @click="activeTab = 'overview'" id="nav-overview-tab" data-bs-toggle="tab"
                                href="#nav-overview" role="tab" aria-controls="nav-overview" aria-selected="true">
                                Overview
                            </a>
                            <a class="nav-item nav-link" :class="{ active: activeTab === 'editProfile' }"
                                @click="activeTab = 'editProfile'" id="nav-editProfile-tab" data-bs-toggle="tab"
                                href="#nav-editProfile" role="tab" aria-controls="nav-editProfile"
                                aria-selected="false">
                                Edit Profile
                            </a>
                            <!--a class="nav-item nav-link" :class="{ active: activeTab === 'settings' }"
                                @click="activeTab = 'settings'" id="nav-settings-tab" data-bs-toggle="tab"
                                href="#nav-settings" role="tab" aria-controls="nav-settings" aria-selected="false">
                                Settings
                            </a-->
                            <a class="nav-item nav-link" :class="{ active: activeTab === 'changePassword' }"
                                @click="activeTab = 'changePassword'" id="nav-changePassword-tab" data-bs-toggle="tab"
                                href="#nav-changePassword" role="tab" aria-controls="nav-changePassword"
                                aria-selected="false">
                                Change Password
                            </a>
                        </div>
                        <div class="tab-content" id="nav-tabContent">
                            <div class="tab-pane fade"
                                :class="{ show: activeTab === 'overview', active: activeTab === 'overview' }"
                                id="nav-overview" role="tabpanel" aria-labelledby="nav-overview-tab">
                                <h5 class="card-title">About</h5>
                                <p class="small fst-italic">About the user</p>

                                <h5 class="card-title">Profile Details</h5>
                                <div class="row">
                                    <div class="col-lg-3 col-md-4 label">Full Name</div>
                                    <div class="col-lg-9 col-md-8">{{ profile.fullName }}</div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-3 col-md-4 label">Country</div>
                                    <div class="col-lg-9 col-md-8">India</div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-3 col-md-4 label">Address</div>
                                    <div class="col-lg-9 col-md-8">31B/34, Building No. 34, S Block, Gurugram</div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-3 col-md-4 label">Phone</div>
                                    <div class="col-lg-9 col-md-8">+91-99999999</div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-3 col-md-4 label">Email</div>
                                    <div class="col-lg-9 col-md-8">demouser@email.com</div>
                                </div>
                            </div>

                            <div class="tab-pane fade profile-edit pt-3"
                                :class="{ active: activeTab === 'editProfile', show: activeTab === 'editProfile' }"
                                id="profile-edit" role="tabpanel">
                                <!-- Profile Edit Form -->
                                <form @submit.prevent="saveChanges">
                                    <div class="row mb-3">
                                        <label for="profileImage" class="col-md-4 col-lg-3 col-form-label">Profile
                                            Image</label>
                                        <div class="col-md-8 col-lg-9">
                                            <img :src="profile.image" alt="Profile" />
                                            <div class="pt-2">
                                                <a href="#" class="btn btn-primary btn-sm me-2"
                                                    title="Upload new profile image" @click.prevent="uploadImage"><i
                                                        class="bi bi-upload"></i></a>
                                                <a href="#" class="btn btn-danger btn-sm"
                                                    title="Remove my profile image" @click.prevent="removeImage"><i
                                                        class="bi bi-trash"></i></a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <label for="fullName" class="col-md-4 col-lg-3 col-form-label">Full
                                            Name</label>
                                        <div class="col-md-8 col-lg-9">
                                            <input v-model="profile.fullName" name="fullName" type="text"
                                                class="form-control" id="fullName" />
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <label for="about" class="col-md-4 col-lg-3 col-form-label">About</label>
                                        <div class="col-md-8 col-lg-9">
                                            <textarea v-model="profile.about" name="about" class="form-control"
                                                id="about" style="height: 100px"></textarea>
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <label for="Country" class="col-md-4 col-lg-3 col-form-label">Country</label>
                                        <div class="col-md-8 col-lg-9">
                                            <input v-model="profile.country" name="country" type="text"
                                                class="form-control" id="Country" />
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <label for="Address" class="col-md-4 col-lg-3 col-form-label">Address</label>
                                        <div class="col-md-8 col-lg-9">
                                            <input v-model="profile.address" name="address" type="text"
                                                class="form-control" id="Address" />
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <label for="Phone" class="col-md-4 col-lg-3 col-form-label">Phone</label>
                                        <div class="col-md-8 col-lg-9">
                                            <input v-model="profile.phone" name="phone" type="text" class="form-control"
                                                id="Phone" />
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <label for="Email" class="col-md-4 col-lg-3 col-form-label">Email</label>
                                        <div class="col-md-8 col-lg-9">
                                            <input v-model="profile.email" name="email" type="email"
                                                class="form-control" id="Email" />
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <label for="Twitter" class="col-md-4 col-lg-3 col-form-label">Twitter
                                            Profile</label>
                                        <div class="col-md-8 col-lg-9">
                                            <input v-model="profile.twitter" name="twitter" type="text"
                                                class="form-control" id="Twitter" />
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <label for="Facebook" class="col-md-4 col-lg-3 col-form-label">Facebook
                                            Profile</label>
                                        <div class="col-md-8 col-lg-9">
                                            <input v-model="profile.facebook" name="facebook" type="text"
                                                class="form-control" id="Facebook" />
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <label for="Instagram" class="col-md-4 col-lg-3 col-form-label">Instagram
                                            Profile</label>
                                        <div class="col-md-8 col-lg-9">
                                            <input v-model="profile.instagram" name="instagram" type="text"
                                                class="form-control" id="Instagram" />
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <label for="Linkedin" class="col-md-4 col-lg-3 col-form-label">Linkedin
                                            Profile</label>
                                        <div class="col-md-8 col-lg-9">
                                            <input v-model="profile.linkedin" name="linkedin" type="text"
                                                class="form-control" id="Linkedin" />
                                        </div>
                                    </div>

                                    <div class="text-center">
                                        <button type="submit" class="btn btn-primary">Save Changes</button>
                                    </div>
                                </form><!-- End Profile Edit Form -->
                            </div>

                            <!--div class="tab-pane fade"
                                :class="{ show: activeTab === 'settings', active: activeTab === 'settings' }"
                                id="nav-settings" role="tabpanel" aria-labelledby="nav-settings-tab">
                                <h5 class="card-title">Settings</h5>
                                <form>
                                    <div class="row mb-3">
                                        <label for="emailNotifications" class="col-md-4 col-lg-3 col-form-label">Email
                                            Notifications</label>
                                        <div class="col-md-8 col-lg-9">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="marketingandpromo"
                                                    checked>
                                                <label class="form-check-label" for="marketingandpromo">Marketing and
                                                    Promo Offers</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="changesMade"
                                                    checked>
                                                <label class="form-check-label" for="changesMade">Changes made to
                                                    your account</label>
                                            </div>

                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="newProducts"
                                                    checked>
                                                <label class="form-check-label" for="newProducts">New products
                                                    info</label>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="text-center">
                                        <button type="submit" class="btn btn-primary">Save Changes</button>
                                    </div>
                                </form>
                            </div-->

                            <div class="tab-pane fade"
                                :class="{ show: activeTab === 'changePassword', active: activeTab === 'changePassword' }"
                                id="nav-changePassword" role="tabpanel" aria-labelledby="nav-changePassword-tab">
                                <h5 class="card-title">Change Password</h5>
                                <form>
                                    <div class="row mb-3">
                                        <label for="currentPassword" class="col-md-4 col-lg-3 col-form-label">Current
                                            Password</label>
                                        <div class="col-md-8 col-lg-9">
                                            <input type="password" class="form-control" id="currentPassword" />
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <label for="newPassword" class="col-md-4 col-lg-3 col-form-label">New
                                            Password</label>
                                        <div class="col-md-8 col-lg-9">
                                            <input type="password" class="form-control" id="newPassword" />
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <label for="confirmPassword" class="col-md-4 col-lg-3 col-form-label">Confirm
                                            New Password</label>
                                        <div class="col-md-8 col-lg-9">
                                            <input type="password" class="form-control" id="confirmPassword" />
                                        </div>
                                    </div>

                                    <div class="text-center">
                                        <button type="submit" class="btn btn-primary">Change Password</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import store from '@/store';

export default {
    data() {
        return {
            activeTab: 'overview', // Default tab
            profile: {
                image: 'https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1722643200&semt=ais_hybrid',
                fullName: store.state.user.username,
                about: 'About the user.',
                country: 'India',
                address: '31B/34, Building No.34, S Block, Gurugram',
                phone: '+91-9999999999',
                email: 'demouser@email.com',
                twitter: 'https://twitter.com/#',
                facebook: 'https://facebook.com/#',
                instagram: 'https://instagram.com/#',
                linkedin: 'https://linkedin.com/#'
            }
        };
    },
    methods: {
        saveChanges() {
            // Handle saving profile changes
        }
    }
};
</script>

<style scoped>
/* Add your styles here */
</style>